import { createApp } from 'vue'
// note: order is very important on all of these bootstrap imports
import 'bootstrap'

import './bootstrap5.css'
import './assets/fonts/GlyphIcons/glyphicons.css'
import './main.css'

import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from 'vue-gtag'

import kbModal from './kb-bootstrap-components/KBModal.vue'
import kbButtonGroup from './kb-bootstrap-components/KBButtonGroup'
import kbDropdown from './kb-bootstrap-components/KBDropdown'
import kbDropdownItem from './kb-bootstrap-components/KBDropdownItem'
import kbDropdownDivider from './kb-bootstrap-components/KBDropdownDivider'
import kbButton from './kb-bootstrap-components/KBButton'
import kbContainer from './kb-bootstrap-components/KBContainer'
import kbRow from './kb-bootstrap-components/KBRow'
import kbCol from './kb-bootstrap-components/KBCol'
import kbForm from './kb-bootstrap-components/KBForm'
import kbFormGroup from './kb-bootstrap-components/KBFormGroup'
import kbFormGroupAppend from './kb-bootstrap-components/KBFormGroupAppend'
import kbFormInput from './kb-bootstrap-components/KBFormInput'
import kbFormTextarea from './kb-bootstrap-components/KBFormTextarea'
import kbFormInvalidFeedback from './kb-bootstrap-components/KBFormInvalidFeedback'
import kbFormSelect from './kb-bootstrap-components/KBFormSelect'
import kbFormCheckbox from './kb-bootstrap-components/KBFormCheckbox'
import kbFormText from './kb-bootstrap-components/KBFormText'
import SimpleTypeahead from 'vue3-simple-typeahead'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

let gtagConfig = {
    config: {
        id: 'G-BMQ2TG8QBH',
        params: {
            anonymize_ip: true
        }
    }
}

let app = createApp(App).use(store).use(router).use(VueGtag, gtagConfig, router).use(SimpleTypeahead)

app.component('kbModal', kbModal)
app.component('kbButtonGroup', kbButtonGroup)
app.component('kbDropdown', kbDropdown)
app.component('kbDropdownItem', kbDropdownItem)
app.component('kbDropdownDivider', kbDropdownDivider)
app.component('kbButton', kbButton)
app.component('kbContainer', kbContainer)
app.component('kbRow', kbRow)
app.component('kbCol', kbCol)
app.component('kbForm', kbForm)
app.component('kbFormGroup', kbFormGroup)
app.component('kbFormGroupAppend', kbFormGroupAppend)
app.component('kbFormInput', kbFormInput)
app.component('kbFormTextarea', kbFormTextarea)
app.component('kbFormInvalidFeedback', kbFormInvalidFeedback)
app.component('kbFormSelect', kbFormSelect)
app.component('kbFormCheckbox', kbFormCheckbox)
app.component('kbFormText', kbFormText)

app.mount('#app')
