<template>
    <label>
    <input style='margin-right: 0.25rem' type="checkbox" :checked="modelValue === value" @click="updateValue">
    <slot></slot>
    </label>
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: 'text'
        },
        uncheckedValue: {
            type: String,
            default: 'text'
        }
    },
    emits: ['update:modelValue'],
    methods: {
        updateValue(event) {
            let newValue = event.target.checked ? this.value : this.uncheckedValue
            this.$emit('update:modelValue', newValue)
        }
    }
}
</script>

<style>
</style>
