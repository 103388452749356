<template>
    <div :class='getColClass()'>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        sm: {
            type: String,
            default: ''
        }
    },
    methods: {
        getColClass() {
            if (this.sm === '') {
                return 'col'
            }
            else {
                return 'col-sm-' + this.sm
            }
        }
    }
}
</script>

<style>
</style>
