export default {
    config: {
        apiBaseUrl: 'https://api.handweaving.net/api/v1/',
        // apiBaseUrl: 'https://betaapi.handweaving.net/api/v1/',
        // apiBaseUrl: 'http://localhost:63291/api/v1/',
        drawdownImagesBaseUrl: 'https://cdndrafts-01-2019.handweaving.net/drafts/',
        siteImagesBaseUrl: 'https://cdndrafts-01-2019.handweaving.net/site/',
        digitalArchiveBaseUrl: 'https://cdnmedia.handweaving.net/DigitalArchive/',
        arizonaDigitalArchiveBaseUrl: 'https://www2.cs.arizona.edu/patterns/weaving/',
        digitalDownloadSamplesBaseUrl: 'https://cdnmedia.handweaving.net/DownloadSamples/',
        storeProductsBaseUrl: 'https://store.handweaving.net/products/',
        draftResultsPerPage: 50,
        boardResultsPerPage: 50,
        documentResultsPerPage: 50,
        mostPopularResultsCount: 500,
        newestResultsCount: 500,
        randomResultsCount: 50,
        randomMaxWarpThreadsCount: 100,
        randomMaxWeftThreadsCount: 100
    },
    constants: {
        tieupOrientation: {
            topLeft: 'tl',
            topRight: 'tr',
            bottomLeft: 'bl',
            bottomRight: 'br'
        }
    },
    subscription: {
        // NOTE: The price here is shown in the UI only; to actually change it, edit both here and in the Braintree subscription plan
        annualCost: '34.99',
        formerAnnualCost: '29.99',
        monthlyCost: '6.49'
    }
}
