<template>
    <div>
        <kb-container fluid class="top-padding no-bottom-spacing no-horiz-padding">
            <img style="max-width: 100%" :src='this.getSiteImageUrl("iStock-000015582770-hw-site-banner-caption-v3.jpg")' />
        </kb-container>
        <kb-container fluid class="no-horiz-padding main-content">
            <div class='no-vertical-spacing'>
                <div style class='inline-auto-width'>
                    <h2>Welcome to Handweaving.net!</h2>
                </div>
            </div>
            <p>Digital resources for all weavers: over {{ (Math.round(patternsCount / 1000) * 1000).toLocaleString("en-US") }} drafts, thousands of historic weaving documents, weaving tools, and more.</p>

            <subscriber-login-prompt/>

            <div class='content-section'>
                <h2 class="top-padding">Weaving Drafts</h2>

                <p>We provide complete
                    <router-link to="/drafts">draft collections</router-link> produced from a large number of works on weaving. Samples:</p>
                <div class="inline-auto-width" :key='pattern.PatternID' v-for="pattern in this.$store.state.homePagePatterns">
                    <router-link :to='buildDraftDetailUrl(pattern)' :title="pattern.Tooltip">
                    <img class="draft-image-thumbnail" :src="draftImageUrlFunction(pattern.PatternID, 2, currentOrientation, false, false, false, false, false, false, false, false, false, false, pattern.ImagesExist)" :alt="'Drawdown Image: ' + pattern.Tooltip" />
                    </router-link>
                </div>

                <div class="bottom-padding">
                    <kb-button variant='primary' to="/drafts">Browse Drafts &raquo;</kb-button>
                </div>
            </div>

            <div class='content-section'>
                <h2 style='display: inline-block; padding-right: 1rem;' class="top-padding">Draft Editor</h2>
            </div>

            <div>
                <p>Easily create new drafts from scratch or edit any existing draft.
                </p>
                <p>
                    <img style="width: 11rem; vertical-align: top; margin-right: 1rem" :src='this.getSiteImageUrl("hw-33040-no-border.png")' />
                    <img style="width: 13rem;  vertical-align: top" :src='this.getSiteImageUrl("hw-33040-pw-border.png")' />
                </p>
                <div class="right-padding bottom-padding">
                    <a class="btn btn-primary" target="_blank" href="https://youtu.be/qYTB2EPmRkA">Draft Editor Video Introduction &raquo;</a>
                    (2 minutes)
                    <br/><br/>
                    <a class="btn btn-primary" target="_blank" href="https://youtu.be/Z8IMDk4N6S0">Draft Editor Full Video Tutorial &raquo;</a>
                    (18 minutes)
                </div>
            </div>

            <div class='content-section'>
                <h2 style='display: inline-block; padding-right: 1rem;' class="top-padding">Draft Repeats Tool</h2>
            </div>

            <div>
                <p>Choose the number of repeats for any draft on the site or those that you upload.
                </p>
                <p>
                    <img style="width: 11rem; vertical-align: top; margin-right: 1rem" :src='this.getSiteImageUrl("hw-34196-2x.png")' />
                    <img style="width: 17rem;  vertical-align: top" :src='this.getSiteImageUrl("hw-34196-4x.png")' />
                </p>
                <div class="right-padding bottom-padding">
                    <a class="btn btn-primary" target="_blank" href="https://youtu.be/s0qDG0oyXX4">Draft Repeats Video Tutorial &raquo;</a>
                </div>
            </div>

            <div class='content-section'>
                <h2 style='display: inline-block; padding-right: 1rem;' class="top-padding">Draft Color Editor </h2>
                <img style='max-width: 12rem; padding-bottom: 0.3rem;' :src='this.getSiteImageUrl("color-bar.png")' />
            </div>

            <div>
                <p>Instantly apply different colors to any Handweaving.net draft or to your own drafts!</p>
                <p>
                    <img style="max-width: 100%; vertical-align: top" :src='this.getSiteImageUrl("63611-small-7-tl.png")' />
                    <!-- <img class="draft-image-thumbnail" :src="draftImageUrlFunction(63611, 4, currentOrientation, false, false, false, false, false, false, false, false, false, false, true)" alt="Color Draft Drawdown Image" /> -->
                </p>
                <div class="right-padding bottom-padding">
                    <kb-button variant='primary' to='/draft-editor-introduction'>Learn More &raquo;</kb-button>
                </div>
            </div>

            <div class='content-section'>
                <h2 style='display: inline-block; padding-right: 1rem;' class="top-padding">Profile Drafting and Block Substitution </h2>
            </div>

            <div>
                <p>Use any draft on the site or to your own drafts as profiles,
                    using blocks of more than 35 different weave structures!
                </p>
                <p>
                    <img style="max-width: 100%; vertical-align: top" :src='this.getSiteImageUrl("9260-4-tr-a.png")' />
                    <img style="max-width: 100%;  vertical-align: top" :src='this.getSiteImageUrl("9260-twill-variant-5-ends-m.png")' />
                </p>
                <div class="right-padding bottom-padding">
                    <a class="btn btn-primary" target="_blank" href="https://www.youtube.com/watch?v=6HtqhfuFl8E">Profile Drafting Video Tutorial &raquo;</a>
                </div>
            </div>

            <div class='content-section'>
                <div>
                    <h2 style='display: inline-block; padding-right: 1rem;' class="top-padding">Amalgamation Drafting</h2>
                </div>

                <div>
                    <p>Use most drafts on the site or to your own drafts to create new beautiful amalgamated drafts!
                    </p>
                    <p>
                        <img class='small-bottom-padding' style="max-width: 100%; vertical-align: top" :src='this.getSiteImageUrl("amalgamation-of-79643.png")' />
                        <img class='small-bottom-padding' style="max-width: 100%; vertical-align: top" :src='this.getSiteImageUrl("69912-satinbase3-amalgamation-3.png")' />
                    </p>
                    <div class="right-padding bottom-padding">
                        <a class="btn btn-primary" target="_blank" href="https://www.youtube.com/watch?v=P4cysOpJSKk">Amalgamation Drafting Video Tutorial &raquo;</a>
                    </div>
                </div>
            </div>

            <div class="content-section top-padding">
                <h2>Digital Archive</h2>
                Ralph Griswold's Digital Archive of Documents on Hand Weaving, Lace, and Related Topics is available through this site! This is a fascinating and useful library of more than 8000 high quality digital reproductions of original works.
                <p class='vertical-padding'>
                    <router-link to="/documents">
                        <img class='right-padding bottom-padding' :src='this.getSiteImageUrl("SwiftSmall.jpg")' />
                    </router-link>
                    <kb-button variant='primary' to="/documents">Browse Weaving Documents &raquo;</kb-button>
                </p>
            </div>

            <div class='content-section'>
                <h2 style='display: inline-block; padding-right: 1rem;' class="top-padding">Video Tutorials </h2>
            </div>

            <div>
                <p>Our YouTube channel with short video tutorials helps you learn to get the most from this site!</p>
                <div class="right-padding bottom-padding">
                    <a class="btn btn-primary" target="_blank" href="https://www.youtube.com/channel/UCbQhW5AwY9hYsXAXbyg3qmQ/videos">View our Video Tutorials &raquo;</a>
                </div>
            </div>

            <div class='content-section'>
                <h2 class="top-padding">Gallery</h2>
            </div>
            <p>
                Our gallery page on Facebook lets you share and discuss projects made using Handweaving.net drafts! Please share photos of your beautiful weaving to inspire others.
            </p>

            <div class="right-padding bottom-padding">
                <a class="btn btn-primary" target="_blank" href="https://www.facebook.com/Handweaving.net">Visit our Facebook Gallery &raquo;</a>
            </div>

            <div class="content-section top-padding">
                <h2>Handweaving.net Store</h2>
                We offer
                <a router-link to="/digital-downloads">digital downloads</a> of most draft collections, and CDs with nearly all of the documents in the Griswold Archive on Weaving, Textiles, Lace, and Related Topics!
                <p class='vertical-padding'>
                    <a href="">
                        <img class='right-padding bottom-padding' :src='this.getProductImageUrl("TaleOfTwoTieups.png")' />
                    </a>
                    <a  class="btn btn-primary" href="http://store.handweaving.net" target="_blank">Visit our Store &raquo;</a>
                </p>
            </div>
            <help-us-button/>
            <mail-chimp-signup/>
        </kb-container>

    </div>
</template>

<script>
import dataService from '../mixins/dataService.js'
import mediaService from '../mixins/mediaService.js'
import urlsService from '../mixins/urlsService.js'
import helpUsButton from './HelpUsButton.vue'
import mailChimpSignup from './MailChimpSignup.vue'
import subscriberLoginPrompt from './SubscriberLoginPrompt.vue'

export default {
    mixins: [dataService, mediaService, urlsService],
    name: 'Home',

    data() {
        return {
            patternsCount: ''
        }
    },
    mounted() {
        this.$store.commit('setPageTitle', 'Weaving Draft and Documents Archive')
        this.fetchData()
    },
    components: {
        helpUsButton: helpUsButton,
        mailChimpSignup: mailChimpSignup,
        subscriberLoginPrompt: subscriberLoginPrompt
    },
    methods: {
        fetchData() {
            let countToRetrieve = 5
            let maxWarpThreads = 75
            let maxWeftThreads = 75

            let minShafts = 1
            let maxShafts = 100
            let maxTreadles = 100
            let maxFloatLength = 100

            if (this.$store.state.homePagePatterns.length === 0) {
                this.getRandomPatterns(countToRetrieve, minShafts, maxShafts, maxTreadles, maxFloatLength, maxWarpThreads, maxWeftThreads).then(resp => {
                    let patternsList = resp.data
                    this.$store.commit('setHomePagePatterns', patternsList)
                })
            }

            this.getPatternsCount().then(resp => {
                this.patternsCount = resp.data
            })
        },
        navigateToSubscription() {
            this.$router.push({ name: 'Subscription' })
        },
        showHideModal() {
            this.$refs.modalTest.show()
        }
    },

    computed: {
        isSubscriber: function() {
            return this.$store.getters.isSubscriber
        },
        draftImageUrlFunction: function() {
            return this.$store.state.draftImageUrlFunction
        },
        currentOrientation: function() {
            if (this.$store.getters.isSubscriber) {
                return this.$store.state.authUserSettings.Orientation
            }
            return 'tl'
        }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.draft-image-thumbnail {
    vertical-align: bottom;
    padding: 0.6rem;
}

.content-section {
    border-top: solid 1px #cfcfcf;
}

.main-content {
    padding-top: 1rem;
}

</style>
