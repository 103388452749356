<template>
    <li><hr class="dropdown-divider"></li>
</template>

<script>

export default {
    props: {
    }
}
</script>

<style>
</style>
