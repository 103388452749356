<template>
    <textarea class="form-control" :rows='rows' :placeholder="placeholder" :required='required' :value="modelValue" @input="updateValue" />
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            value: null
        },
        placeholder: {
            type: String,
            default: ''
        },
        rows: {
            Number,
            default: 3
        }
    },
    emits: ['update:modelValue'],
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.value)
        }
    }
}
</script>

<style>
</style>
