import cfg from '../config/config'

export default {
    methods: {
        draftImageUrl(patternId, cellPixelSize, orientation, isTurned, isThreadingStraightened, isTreadlingStraightened, isThreadingPoint, isTreadlingPoint, originalHasStraightThreading, originalHasStraightTreadling, originalHasPointThreading, originalHasPointTreadling, showLiftplan, imagesExist, showFullDraft) {
            if (imagesExist) {
                let draftImagesBaseUrl = cfg.config.drawdownImagesBaseUrl

                let threadingOption = 'none'
                if (isThreadingStraightened) {
                    threadingOption = 'straight'
                }
                else if (isThreadingPoint) {
                    threadingOption = 'point'
                }

                let treadlingOption = 'none'
                if (isTreadlingStraightened) {
                    treadlingOption = 'straight'
                }
                else if (isTreadlingPoint) {
                    treadlingOption = 'point'
                }

                // console.log('threadingOption before replacement: ' + threadingOption)
                // console.log('treadlingOption before replacement: ' + treadlingOption)

                if (threadingOption==='straight' && originalHasStraightThreading) {
                    threadingOption = 'none'
                }
                else if (threadingOption==='point' && originalHasPointThreading) {
                    threadingOption = 'none'
                }
                if (treadlingOption==='straight' && originalHasStraightTreadling) {
                    treadlingOption = 'none'
                }
                else if (treadlingOption==='point' && originalHasPointTreadling) {
                    treadlingOption = 'none'
                }

                // console.log('threadingOption after replacement in relation to original draft: ' + threadingOption)
                // console.log('treadlingOption after replacement in relation to original draft: ' + treadlingOption)

                let url = draftImagesBaseUrl + this.draftSubdirectoryName(patternId) + '/' + this.draftImageBaseFilename(patternId, cellPixelSize, orientation, isTurned, threadingOption, treadlingOption, showLiftplan, !showFullDraft)
                // console.log('image url: ' + url)

                return url
            }
            else {
                // images don't exist yet for new pattern, generate them through the api instead
                // TODO: Change this to include a user id, then validate server side, then make showFullDraft dependent upon that rather than passed in
                let apiBaseUrl = cfg.config.apiBaseUrl
                let imageUrl = apiBaseUrl + 'patterns/drawdownimage'
                imageUrl += '?PatternID=' + patternId
                imageUrl += '&CellPixelSize=' + cellPixelSize
                imageUrl += '&Orientation=' + orientation
                imageUrl += '&Turned=' + (isTurned ? 'true' : 'false')
                imageUrl += '&ThreadingStraightened=' + (isThreadingStraightened ? 'true' : 'false')
                imageUrl += '&TreadlingStraightened=' + (isTreadlingStraightened ? 'true' : 'false')
                imageUrl += '&ThreadingPoint=' + (isThreadingPoint ? 'true' : 'false')
                imageUrl += '&TreadlingPoint=' + (isTreadlingPoint ? 'true' : 'false')
                imageUrl += '&Full=' + showFullDraft
                return imageUrl
            }
        },

        draftSubdirectoryName(patternId) {
            let lastDigitOfPatternId = (patternId + '').slice(-1)
            let subdirName = lastDigitOfPatternId + '/' + patternId
            return subdirName
        },

        draftImageBaseFilename(patternId, cellPixelSize, orientation, isTurned, threadingOption, treadlingOption, showLiftplan, isDrawdownOnly) {
            let orientationString = ''
            let threadingOptionsString = ''

            if (cellPixelSize > 1) {
                if (isDrawdownOnly) {
                    threadingOptionsString = '-tl-d'
                }
                else {
                    if (threadingOption ==='none') {
                        if (treadlingOption === 'none') {
                            threadingOptionsString = ''
                        }
                        else if (treadlingOption === 'straight') {
                            threadingOptionsString = '-r'
                        }
                        else if (treadlingOption === 'point') {
                            threadingOptionsString = '-q'
                        }
                    }
                    else if (threadingOption === 'straight') {
                        if (treadlingOption === 'none') {
                            threadingOptionsString = '-o'
                        }
                        else if (treadlingOption === 'straight') {
                            threadingOptionsString = '-s'
                        }
                        else if (treadlingOption === 'point') {
                            threadingOptionsString = '-m'
                        }
                    }
                    else if (threadingOption === 'point') {
                        if (treadlingOption === 'none') {
                            threadingOptionsString = '-n'
                        }
                        else if (treadlingOption ==='straight') {
                            threadingOptionsString = '-l'
                        }
                        else if (treadlingOption === 'point') {
                            threadingOptionsString = '-p'
                        }
                    }

                    if (isTurned) {
                        if (threadingOptionsString.length > 0) {
                            threadingOptionsString += 't'
                        }
                        else {
                            threadingOptionsString += '-t'
                        }
                    }

                    if (showLiftplan) {
                        if (threadingOptionsString.length > 0) {
                            threadingOptionsString += 'z'
                        }
                        else {
                            threadingOptionsString += '-z'
                        }
                    }

                    switch (orientation) {
                        case cfg.constants.tieupOrientation.topLeft:
                            orientationString = '-tl'
                            break
                        case cfg.constants.tieupOrientation.bottomLeft:
                            orientationString = '-bl'
                            break
                        case cfg.constants.tieupOrientation.bottomRight:
                            orientationString = '-br'
                            break
                        case cfg.constants.tieupOrientation.topRight:
                            orientationString = '-tr'
                            break
                        default:
                            orientationString = '-tl'
                            break // shouldn't happen, default to top left if does
                    }
                }
            }
            else {
                orientationString = '-tl' // for drawdown-only images, we only have top left orientation
            }

            let filename = patternId + '-' + cellPixelSize + threadingOptionsString + orientationString + '.png'
            return filename
        },

        getCollectionCoverImageUrl(book) {
            let baseUrl = cfg.config.siteImagesBaseUrl
            let url = baseUrl + 'BookCovers/' + book.CoverPhotoName
            return url
        },

        getCollectionCoverImageUrlByFilename(filename) {
            let book = {}
            book.CoverPhotoName = filename
            return this.getCollectionCoverImageUrl(book)
        },

        getSiteImageUrl(filename) {
            let baseUrl = cfg.config.siteImagesBaseUrl
            let url = baseUrl + 'Pages/' + filename
            return url
        },

        getProductImageUrl(filename) {
            let baseUrl = cfg.config.siteImagesBaseUrl
            let url = baseUrl + 'Products/' + filename
            return url
        },

        getWeavingSoftwareImageUrl(filename) {
            let baseUrl = cfg.config.siteImagesBaseUrl
            let url = baseUrl + 'Software/' + filename
            return url
        },

        getSampleDownloadUrl(filename) {
            let baseUrl = cfg.config.digitalDownloadSamplesBaseUrl
            let url = baseUrl + filename
            return url
        },

        calculateDraftInitialDisplaySize(draft, windowWidth, includeThumbnails) {
            let cellPixelSize = 2

            // console.log('in calculateDraftInitialDisplaySize, draft: ' + JSON.stringify(draft))

            // use default logic if we don't know the window width for some reason
            if (!windowWidth || windowWidth <= 0) {
                if (draft.WarpThreads * draft.WeftThreads > 20000) cellPixelSize = 2
                else if (draft.WarpThreads * draft.WeftThreads > 10000) cellPixelSize = 3
                else if (draft.WarpThreads * draft.WeftThreads > 5000) cellPixelSize = 5
                else if (draft.WarpThreads * draft.WeftThreads > 1000) cellPixelSize = 7
                else if (draft.WarpThreads * draft.WeftThreads > 500) cellPixelSize = 9
                else cellPixelSize = 11
            }
            else {
                // (threads + treadles + 1) * (cellPixelSize + 1) = width, because there are dividing lines,
                // one per cell and a 1 cell space between treadling and drawdown, plus 5 more cell spaces for thread colors and spacer
                // so desired cellPixelSize = (width / (threads + treadles + 1)) - 1
                cellPixelSize = windowWidth / (draft.WarpThreads + draft.Treadles + 6) - 1

                // available sizes are 1, 2, 3, 4, 5, 7, 9, 11

                if (cellPixelSize >= 11) {
                    cellPixelSize = 11
                }
                else if (cellPixelSize >= 9) {
                    cellPixelSize = 9
                }
                else if (cellPixelSize >= 7) {
                    cellPixelSize = 7
                }
                else if (cellPixelSize >= 5) {
                    cellPixelSize = 5
                }
                else if (cellPixelSize >= 4) {
                    cellPixelSize = 4
                }
                else if (cellPixelSize >= 3) {
                    cellPixelSize = 3
                }
                else if (cellPixelSize >= 2) {
                    cellPixelSize = 2
                }
                else if (cellPixelSize >= 1) {
                    cellPixelSize = 1
                }
                else cellPixelSize = 1

                if (!includeThumbnails) {
                    // thumbnails have no dividing lines or tieup/treadling/threading, we don't want them on detail page
                    if (cellPixelSize === 1) {
                        cellPixelSize = 2
                    }
                }
            }
            return cellPixelSize
        },

        calculateDraftThumbnailDisplaySize(draft, baseSize) { // baseSize is 1, 2, or 3
            if (draft.WarpThreads * draft.WeftThreads > 20000) {
                return baseSize
            }
            else {
                return baseSize + 1
            }
        },

        wifFilename(patternId, isLiftplan) {
            let wifUrl = patternId
            if (isLiftplan) {
                wifUrl += '-liftplan'
            }
            wifUrl += '.wif'
            return wifUrl
        },

        wifUrl(patternId, isLiftplan) {
            let baseUrl = cfg.config.drawdownImagesBaseUrl
            let url = baseUrl + this.draftSubdirectoryName(patternId) + '/' + this.wifFilename(patternId, isLiftplan)
            return url
        },

        archiveFileUrl(daItemFile) {
            let baseUrl = cfg.config.arizonaDigitalArchiveBaseUrl
            // console.log('baseUrl: ' + baseUrl)
            // console.log('daItemFile: ' + JSON.stringify(daItemFile))
            if (daItemFile.ItemId > 8414) {
                baseUrl = cfg.config.digitalArchiveBaseUrl
            }
            let url = baseUrl + daItemFile.Subfolder + '/'
            if (daItemFile.ContentType === 'Sample') {
                url += 'SAMPLES/'
            }
            else if (daItemFile.ContentType === 'Contents') {
                url += 'CONTENTS/'
            }
            else if (daItemFile.ContentType === 'Review') {
                url += 'REVIEWS/'
            }
            else if (daItemFile.ContentType === 'Cover') {
                url += 'COVERS/'
            }
            url += daItemFile.Filename
            return url
        },

        storeProductUrl(productStoreUrlToken) {
            let baseUrl = cfg.config.storeProductsBaseUrl
            let url = baseUrl + productStoreUrlToken
            return url
        },

        storeProductImageUrl(sku) {
            let filename = sku.toLowerCase() + '.png'
            return this.getProductImageUrl(filename)
        }
    }
}
