<template>
    <nav class="navbar fixed-top nav-bar navbar-expand-sm navbar-light">
        <div class="container-fluid">
            <a class="navbar-brand" @click='navigateTo("Home")' >
                <span class="drawdown-blue logo-typeface">Handweaving</span><span class="drawdown-red logo-typeface">.net</span>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-sm-0">
                    <li class="nav-item"><a class="nav-link" @click='navigateTo("Drafts")'>Drafts</a></li>
                    <li class="nav-item"><a class="nav-link" @click='navigateTo("Docs")'>Docs</a></li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="storeDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Store
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="storeDropdown">
                            <li class="nav-item"><a class="dropdown-item" @click='collapseHamburgerMenu()' target='_blank' href='http://store.handweaving.net'>Browse our Store</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("DigitalDownloads")'>Downloads</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="aboutDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            About
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="aboutDropdown">
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("About")'>About Us</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("Acknowledgements")'>Acknowledgements</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("Contact")'>Contact Us</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("Updates")'>Site Updates</a></li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="helpDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Help
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="helpDropdown">
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("Help")'>Site Help / FAQ</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='collapseHamburgerMenu()' target='_blank' href='https://www.youtube.com/channel/UCbQhW5AwY9hYsXAXbyg3qmQ/videos'>Video Tutorials</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("WeavingSoftware")'>Weaving Software</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateTo("Contact")'>Contact Us</a></li>
                        </ul>
                    </li>
                    <li v-if='this.isAuthenticated' class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" id="accountDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Account
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="accountDropdown">
                            <li class="nav-item"><a class="dropdown-item" v-if='this.isAuthenticated'  @click='navigateTo("Account")'>My&nbsp;Account</a></li>
                            <li class="nav-item"><a class="dropdown-item" v-if='this.isAuthenticated'  @click='navigateTo("Subscription")'>My&nbsp;Subscription</a></li>
                            <li class="nav-item"><a class="dropdown-item" v-if='this.isAuthenticated'  @click='navigateTo("Settings")'>My&nbsp;Settings</a></li>
                            <li class="nav-item"><a class="dropdown-item" v-if='!this.isAuthenticated'  @click='navigateTo("Login")'>Sign&nbsp;In</a></li>
                            <li class="nav-item"><a class="dropdown-item" v-if='!this.isAuthenticated'  @click='navigateTo("Account")'>Register</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateToClipboard()'>My&nbsp;Clipboard</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateToBoards()'>My&nbsp;Draft&nbsp;Boards</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateToTagCloud("mytags")'>My&nbsp;Draft&nbsp;Tags</a></li>
                            <li class="nav-item"><a class="dropdown-item" @click='navigateToLiked()'>My&nbsp;Liked&nbsp;Drafts</a></li>
                            <li class="nav-item"><a class="dropdown-item" v-if='this.isAuthenticated' @click='logout()'>Sign&nbsp;Out</a></li>
                        </ul>
                    </li>
                    <li class="nav-item"><a class="nav-link" v-if='!this.isAuthenticated' @click='navigateTo("Login")'>Sign&nbsp;In</a></li>
                </ul>
                <div class='d-flex'>
                    <input @change='navigateToKeyword()' class="form-control mr-sm-2 me-2" v-model='keyword' type="search" placeholder="Draft # or keyword" aria-label="Search">
                    <button class="btn btn-outline-secondary" type="button" @click='navigateToKeyword()'><span class="glyphicon glyphicon-search"/></button>
                </div>
            </div>
        </div>
    </nav>
<!--
    <nav class="navbar navbar-expand-sm navbar-light bg-light">
        <div class='container-fluid'>

            <div class="collapse navbar-collapse" id='nav-collapse'>
                <form class="form-inline my-2 my-lg-0">
                <input class="form-control mr-sm-2" type="search" placeholder="Draft # or keyword" aria-label="Search">
                <kb-button size='sm' variant='secondary' @click='navigateToKeyword()' type="button"><span class="glyphicon glyphicon-search" aria-hidden="true"/></kb-button>
                </form>
            </div>
        </div>
    </nav>
-->
</template>

<script>

import UtilityService from '../mixins/utilityService.js'

export default {
    mixins: [UtilityService],
    data() {
        return {
            keyword: ''
        }
    },
    methods: {
        logout: function() {
            window.localStorage.removeItem('user')
            this.$store.commit('clearAuthUser')
            this.navigateTo('Home')
        },
        navigateTo: function(routeName) {
            // console.log('in NavBar navigateTo')
            // () => { location.href = this.$route.hash } works around a bug in bootstrap-vue after release 2.0.0-rc.11 where navigation to same route leaves the menu open
            this.collapseHamburgerMenu()
            this.$router.push({ name: routeName }, undefined, () => {
                location.href = this.$route.hash
            })
            return false
        },
        navigateToClipboard() {
            // console.log('in NavBar navigateToClipboard')
            // () => { location.href = this.$route.hash } works around a bug in bootstrap-vue after release 2.0.0-rc.11 where navigation to same route leaves the menu open
            this.$router.push({ name: 'DraftSearch', params: { searchType: 'clipboard' }, query: { } }, undefined, () => { location.href = this.$route.hash })
        },
        navigateToLiked() {
            // console.log('in NavBar navigateToLiked')
            // () => { location.href = this.$route.hash } works around a bug in bootstrap-vue after release 2.0.0-rc.11 where navigation to same route leaves the menu open
            this.$router.push({ name: 'DraftSearch', params: { searchType: 'mylikes' }, query: { } }, undefined, () => { location.href = this.$route.hash })
        },
        navigateToKeyword() {
            // console.log('in NavBar navigateToKeyword')
            // () => { location.href = this.$route.hash } works around a bug in bootstrap-vue after release 2.0.0-rc.11 where navigation to same route leaves the menu open
            let cleanedKeyword = this.keyword.trim()
            cleanedKeyword = cleanedKeyword.replace('#', '')
            // console.log('in NavBar search, current route: ' + this.$route.name)

            let nextRouteObj = {
                name: 'DraftSearch',
                params: { searchType: 'keyword' },
                query: {
                    keyword: cleanedKeyword
                }
            }

            if (this.$route.name !== 'DraftSearch') {
                // console.log('in NavBar search, different route, nextRouteObj: ' + JSON.stringify(nextRouteObj))
                if (cleanedKeyword.length > 0) {
                    this.$router.push(nextRouteObj)
                }
            }
            else {
                this.setQuerystringValue('keyword', cleanedKeyword)
            }
        },
        navigateToTagCloud(searchType) {
            // console.log('in NavBar navigateToTagCloud')
            // () => { location.href = this.$route.hash } works around a bug in bootstrap-vue after release 2.0.0-rc.11 where navigation to same route leaves the menu open
            this.$router.push({ name: 'TagCloud', params: { searchType: searchType } }, undefined, () => { location.href = this.$route.hash })
        },
        navigateToBoards() {
            // console.log('in NavBar navigateToBoards')
            // () => { location.href = this.$route.hash } works around a bug in bootstrap-vue after release 2.0.0-rc.11 where navigation to same route leaves the menu open
            this.$router.push({ name: 'MyBoards', params: { } }, undefined, () => { location.href = this.$route.hash })
        }
    },
    computed: {
        isAuthenticated: function() {
            return this.$store.getters.isAuthenticated
        },
        authUser: function() {
            return this.$store.state.authUser
        }
    },
    watch: {
        $route: function() {
            this.keyword=''
        }
    }
}
</script>

<style scoped>
.nav-bar {
    background-color: #f9f8f5;
    color: #777;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-right: 0;
    font-size: 1em;
    border-color: #e7e7e7;
    border-bottom-width: 1px;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: solid;
    border-left-style: none;
}

.no-wrap {
    white-space: nowrap;
    overflow: hidden;
}

.logo-typeface {
    font-family: tendernessregular;
    font-size: 1.2rem;
}

li { cursor: pointer; }

.navbar-brand { cursor: pointer; }

</style>
