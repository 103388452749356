<template>
    <div style='display: inline-block; padding: 0; margin: 0'>
        <select :style='getSelectStyle()' :value='modelValue' class='form-select form-select-sm' style='display: inline-block' @change='optionClicked($event)'>
            <option class='option-font-size' v-for='o in options' :key='o.value' :value='o.value'>{{ o.text }}</option>
            <slot></slot>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        modelValue: {
        },
        options: {
            type: Array,
            default: () => []
        },
        selectFontSize: {
            type: String,
            default: '1.0rem'
        },
        selectStyle: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            selected: this.options.length > 0 ? this.options[0] : null
        }
    },
    emits: ['update:modelValue', 'change', 'input'],
    methods: {
        optionClicked(e) {
            let isNumeric = typeof this.modelValue === 'number'
            let selectedValue = isNumeric ? parseInt(e.target.value) : e.target.value.toString()
            // console.log('in optionClicked, e: ' + JSON.stringify(e) + ', isNumeric: ' + isNumeric)
            // console.log('emitting update:modelValue: ' + selectedValue)
            this.$emit('update:modelValue', selectedValue)
            this.$emit('change', selectedValue)
            this.$emit('input', selectedValue) // not actually used by Handweaving.net so far, included for compatibility with native selects
        },
        getSelectStyle() {
            let s = this.selectStyle
            if (s && s.length > 0) {
                s += ';'
            }
            s += ' font-size: ' + this.selectFontSize
            // console.log('selectStyle: ' + s)
            return s
        }
    },
    computed: {
        selectStyleSeparator: function() {
            return this.selectStyle && this.selectStyle.length > 0 ? ';' : ''
        }
    }
}
</script>

<style>
    .option-font-size {
        font-size: 1.0rem;
    }
</style>
