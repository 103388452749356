<template>
    <input ref='inputBox' :autofocus='!autofocus==="true"' :type="type" class="form-control" :style='getStyle()' :placeholder="placeholder" :required='required' :value="modelValue" @change='trimAndUpdateValue' @input="updateValue" />
</template>

<script>
export default {
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'text'
        },
        required: {
            type: Boolean,
            value: null
        },
        autofocus: {
            type: String,
            default: 'false'
        },
        placeholder: {
            type: String,
            default: ''
        },
        inline: {
            type: String,
            default: 'false'
        },
        inlineWidth: {
            type: String,
            default: null
        }
    },
    emits: ['update:modelValue'],
    methods: {
        updateValue(event) {
            // console.log('in updateValue')
            this.$emit('update:modelValue', event.target.value)
        },
        trimAndUpdateValue() {
            // trim the input on update
            // console.log('in trimAndUpdateValue')
            let currentValue = this.$refs.inputBox.value
            this.$refs.inputBox.value = currentValue.trim()
            let newValEvent = {}
            newValEvent.target = {}
            newValEvent.target.value = this.$refs.inputBox.value
            this.updateValue(newValEvent)
        },
        getStyle() {
            let s = ''
            if (this.inline!=='false') {
                s += 'display: inline'
            }
            if (this.inline!=='false' && this.inlineWidth) {
                s += ' width: ' + this.inlineWidth + ';'
                s += ' max-width: ' + this.inlineWidth + ';'
            }
            return s
        }
    }
}
</script>

<style scoped>
    input[type=text] {
        margin-bottom: 0.5rem;
    }
    input[type=email] {
        margin-bottom: 0.5rem;
    }
    input[type=password] {
        margin-bottom: 0.5rem;
    }
</style>
