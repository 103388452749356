<template>
<div>
<hr/>
<h3>Please Help Us Provide This Site</h3>
<p>You can help us continue to provide and expand Handweaving.net!</p>
<kb-button variant="primary" to="/ways-to-help">Help Handweaving.net! <span class="glyphicon glyphicon-heart-empty" aria-hidden="true"/></kb-button>

</div>
</template>

<style scoped>
</style>
