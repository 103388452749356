<template>
    <div :style='style' class='container-fluid'>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        style: {
            type: String,
            default: ''
        }
    },
    methods: {
    }
}
</script>

<style>
</style>
