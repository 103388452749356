<template>
    <div v-if="!isSubscriber" class="right-padding align-top no-vertical-spacing border-box small-box-bottom-padding" style='padding-top: 0.8rem; margin-bottom: 1rem; margin-top: 1rem; background-color: #f9f8f5'>
        <div class="inline-auto-width">
            Subscribe today for full drafts, features, and tools when signed in!&nbsp;&nbsp;
        </div>
        <div class="inline-auto-width" style="white-space: nowrap">
            <kb-button variant='primary' to="/subscription">Learn More &raquo;</kb-button>&nbsp;&nbsp;
            <login-control v-if="!isAuthenticated" prompt='Sign In' :show-as-link='false'/>
        </div>
    </div>
</template>

<script>
import loginControl from './LoginControl.vue'

export default {
    computed: {
        isSubscriber: function() {
            return this.$store.getters.isSubscriber
        },

        isAuthenticated: function() {
            return this.$store.getters.isAuthenticated
        }
    },
    components: {
        loginControl: loginControl
    }
}
</script>

<style scoped>
.small-box-bottom-padding {
    padding-bottom: 6px
}
</style>
