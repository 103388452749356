<template>
    <div style='clear:both; display: block'>
        <hr/>
        <p>
            <!-- note: the script which loads the securty seal is loaded in /index.html -->
            <span id="siteseal">
            </span>
            &copy; 2004 - {{new Date().getFullYear()}} Kris Bruland, Handweaving.net <br/>
        </p>
        <p>
            <router-link to="/privacy-policy">Privacy Policy</router-link>
        </p>
    </div>
</template>

<script>
</script>

<style scoped>
</style>
