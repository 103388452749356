<template>
    <div class="btn-group" role="group" :aria-label="ariaLabel">
    <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        ariaLabel: {
            type: String,
            default: 'Button group with nested dropdown'
        }
    }
}
</script>

<style>
</style>
