<template>
    <div :style='style' class='inline-auto-width'>
        <router-link v-if='to' :title='title' :to='to' :disabled='disabled'>
            <button v-if='to' :style='getInlineStyles()' :type="type" :class='getClassname()'>
                <slot></slot>
            </button>
        </router-link>
        <button v-if='!to' :style='getInlineStyles()' :type="type" :class='getClassname()'>
                <slot></slot>
        </button>
    </div>
</template>

<script>

export default {
    props: {
        ariaLabel: {
            type: String,
            default: 'Button'
        },
        type: {
            type: String,
            default: 'button'
        },
        size: {
            type: String,
            default: ''
        },
        variant: {
            type: String,
            default: 'primary'
        },
        title: {
            type: String,
            default: 'Button'
        },
        to: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: null
        },
        hideBorder: {
            type: String,
            default: ''
        },
        style: {
            type: Object,
            default: null
        }
    },
    methods: {
        getClassname() {
            let c = 'btn btn-' + this.size + ' btn-' + this.variant
            return c
        },
        getInlineStyles() {
            let s = ''
            if (this.hideBorder === 'true') {
                s += 'border: 1px transparent'
            }
            return s
        }
    }
}
</script>

<style>
</style>
