<template>
    <span>
        <kb-button v-if='!showAsLink' variant='primary' @click='goToLogin'>{{ prompt }} &raquo;</kb-button>
        <a href :title='prompt' v-if='showAsLink' @click='goToLogin'>{{ prompt }}</a>
    </span>
</template>

<script>
export default {
    methods: {
        goToLogin: function() {
            this.$router.push({ name: 'Login' })
        }
    },
    props: {
        prompt: {
            type: String,
            required: true
        },
        showAsLink: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style>
</style>
