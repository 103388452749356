<template>
<div>

<!-- Begin MailChimp Signup Form -->
<hr/>
<h3>Join our Mailing List</h3>
<div id="mc_embed_signup">
    <form action="//handweaving.us10.list-manage.com/subscribe/post?u=f2d7c5e8975a0386ecf2de212&amp;id=eef4a1454c" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
            <label for="mce-EMAIL" style="font-weight: normal">Sign up to receive occasional news about Handweaving.net.</label><br />
            <input style='margin-bottom: 0.5rem' type="email" value="" name="EMAIL" class="email form-control form-control-inline" id="mce-EMAIL" placeholder="email address" required>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px;"><input type="text" name="b_f2d7c5e8975a0386ecf2de212_eef4a1454c" tabindex="-1" value=""></div>
            &nbsp;
            <input type="submit" value="Sign Up Now" name="subscribe" id="mc-embedded-subscribe" class="button btn btn-primary" style="display: inline">
            <br />
        </div>
    </form>
</div>

</div>
</template>

<script>
</script>

<style scoped>
.form-control-inline {
    min-width: 0;
    width: auto;
    display: inline;
}
</style>
