<!-- See https://travishorn.com/form-validation-with-vue-js-4d2e7ba8d2fc, bootstrap vue documentation is poor -->
<template>
    <kb-container fluid class='top-padding'>

        <h2 v-if='inSubscribeFlow'>Please Sign In or Create an Account to Subscribe</h2>
        <hr v-if='inSubscribeFlow' />

        <h2 v-if='!showForgotPasswordForm'>Sign In To Existing Account</h2>

        <kb-form v-if='!showForgotPasswordForm' @submit="onSubmit">
            <kb-form-group :class="{ 'is-invalid': formSubmitAttempted && !isValidEmail}">
                <kb-form-input type="email" v-model="form.email" required autofocus='true' placeholder="Enter email" :class="{ 'is-invalid': formSubmitAttempted && !isValidEmail }">
                </kb-form-input>
                <kb-form-invalid-feedback>
                    Enter a valid email address containing an @ symbol
                </kb-form-invalid-feedback>
            </kb-form-group>
            <kb-form-group>
                <kb-form-input type="password" v-model="form.password" required placeholder="Enter password" :class="{ 'is-invalid': formSubmitAttempted && !isValidPassword }">
                </kb-form-input>
                <kb-form-invalid-feedback>
                    Enter your account password
                </kb-form-invalid-feedback>
            </kb-form-group>
            <kb-button type="submit" variant="primary" title='Sign in'>Sign In</kb-button>
            <span v-if='!showForgotPasswordForm' class='left-padding'>
            <a href='#' @click='makeForgotPasswordFormVisible'>Forgot Your Password?</a>
            </span>
        </kb-form>

        <div v-if='loginFailed && !showForgotPasswordForm'>
            <div class='top-padding bottom-padding'>
                <span class='text-danger'>Sign in failed: </span>
                <span class='text-danger'>{{loginFailedReason}}</span>
                <br v-if='hint' /><br v-if='hint' />
                <span v-if='hint'>Hint: {{hint}}</span>
            </div>
        </div>

        <div v-if='!passwordRequestSubmitSuccess && !passwordResetMaxSubmitsReached' class='top-padding bottom-padding'>
            <kb-form v-if='showForgotPasswordForm' @submit='goCreatePasswordResetRequest'>
                <h3>Reset Your Password</h3>
                <span>Enter your email address and we'll send you a link to reset your password.<br/><br/></span>
                <kb-form-group>
                    <kb-form-input @input='resetErrorFlags' :value='form.email' type='email' required placeholder='Enter your email address here...' v-model="form.email" :class="{'is-invalid' : (!isValidEmail || noUserFound) && passwordRequestSubmitAttempted}">
                    </kb-form-input>
                    <kb-form-invalid-feedback>
                        <p v-if='!isValidEmail'>
                        Please enter a valid email address.
                        </p>
                        <p v-if='noUserFound && isValidEmail'>
                            We can't find any accounts registered to this email. Are you sure you typed it correctly?
                        </p>
                    </kb-form-invalid-feedback>
                </kb-form-group>
                <kb-button v-if='!passwordRequestSubmitSuccess' type="submit" variant="primary" title="Submit Request">Send Password Reset Email</kb-button>
            </kb-form>
            <p class='top-padding text-danger' v-if='passwordResetFailedError'>
                Your request could not be sent, Internet or WIFI access failed, please try again later.
            </p>
        </div>

        <div v-if='passwordResetMaxSubmitsReached'>
            If you have an account but cannot remember which email address you used to register, please
            <router-link to='Contact'>contact us</router-link> for assistance!
        </div>

        <div class='top-padding' v-if='passwordRequestSubmitAttempted && passwordRequestSubmitSuccess'>
            <h3>Password Reset Email Sent</h3>
            <div>
                Please check your email for a link which will allow you to reset your password; use within two hours.
            </div>
        </div>

        <div v-if='!showForgotPasswordForm'>
             <hr />
            <h2>Need an Account?</h2>

            <div class='bottom-padding'>
                Basic accounts are free and enable personalized features such as draft clipboards, downloads, and contributing your own drafts.
            </div>

            <kb-button variant='primary' title='Create a new account' @click='goCreateAccount'>Create New Account Now</kb-button>
        </div>

        <kb-modal auto-focus-button='ok' ref='inSubscribeFlowModalRef' title='Sign In Successful' ok-title='Continue »' @ok='handleModalOk' ok-only :no-close-on-backdrop='true' :no-close-on-esc='true'>
            <div class="d-block text-center">
                {{ authUser.Name }} Welcome to Handweaving.net!
            </div>
        </kb-modal>

        <kb-modal auto-focus-button='ok' ref='activeSingleModalRef' :title='"Welcome, " + authUser.Name + "!"' ok-only @ok='handleModalOk' :no-close-on-backdrop='true' :no-close-on-esc='true'>
            <div class="d-block text-center">
                Your subscription lasts until {{ subscriptionExpirationDateWithFullMonth }}.  Thanks for your support!
            </div>
        </kb-modal>

        <kb-modal auto-focus-button='ok' ref='activeRenewsModalRef' :title='"Welcome, " + authUser.Name + "!"' ok-only @ok='handleModalOk' :no-close-on-backdrop='true' :no-close-on-esc='true'>
            <div class="d-block text-center">
                Your {{authUser.SubscriptionType}} subscription renews on {{ subscriptionExpirationDateWithFullMonth }}.  Thanks for your support!
            </div>
        </kb-modal>

        <kb-modal auto-focus-button='ok' ref='expiredModalRef' :title='"Welcome, " + authUser.Name + "!"' ok-title='Renew Subscription »' @ok='handleModalOk' cancel-title='Not Now' @cancel='handleModalCancel' :no-close-on-backdrop='true' :no-close-on-esc='true'>
            <div class="d-block text-center">
                Your subscription has expired, please resubscribe to get more!
            </div>
        </kb-modal>

        <kb-modal auto-focus-button='ok' ref='cancelledModalRef' :title='"Welcome, " + authUser.Name + "!"' ok-title='Resubscribe »' @ok='handleModalOk' cancel-title='Not Now' @cancel='handleModalCancel' :no-close-on-backdrop='true' :no-close-on-esc='true'>
            <div class="d-block text-center">
                Your {{authUser.SubscriptionType}} subscription has been cancelled but may still be used until {{ authUser.SubscriptionExpirationDateWithFullMonth }}.  Please resubscribe to get more!
            </div>
        </kb-modal>

        <kb-modal auto-focus-button='ok' ref='noneModalRef' :title='"Welcome, " + authUser.Name + "!"' ok-title='Continue »' @ok='handleModalOk' cancel-title='Not Now' @cancel='handleModalCancel' :no-close-on-backdrop='true' :no-close-on-esc='true'>
            <div class="d-block text-center">
                Subscribe now to get access to full drafts and many more features!
            </div>
        </kb-modal>
    </kb-container>
</template>

<script>
import dataService from '../mixins/dataService.js'
import utilityService from '../mixins/utilityService.js'

export default {
    mixins: [dataService, utilityService],
    data() {
        return {
            // this is the data model for the form
            form: {
                email: '',
                password: ''
            },
            formSubmitAttempted: false,
            loginFailed: false,
            showForgotPassword: false,
            loginFailedReason: '',
            showForgotPasswordForm: false,
            hint: null,
            resetPasswordEmailAddress: '',
            passwordRequestSubmitSuccess: false,
            noUserFound: false,
            passwordRequestSubmitAttempted: false,
            passwordRequestMaxSubmits: 4,
            passwordResetSubmitCount: 0,
            passwordResetMaxSubmitsReached: false,
            passwordResetFailedError: false
        }
    },
    methods: {

        onSubmit(evt) {
            evt.preventDefault()
            this.formSubmitAttempted = true
            if (!this.entireFormValid()) {
                return false
            }
            this.postLogin(this.form.email, this.form.password).then(resp => {
                let user = resp.data
                this.loginFailed = false
                this.showForgotPassword = false

                if (resp.success) {
                    // either login was successful or user was found but password didn't match
                    if (!this.guidIsEmpty(user.LoginToken)) {
                        // successful if login token returned
                        this.loginFailed = false

                        // success, persist the user and show message/dialog
                        this.$store.commit('setAuthUser', user)
                        let loggedInUser = { UserID: user.UserID, LoginToken: user.LoginToken }
                        window.localStorage.setItem('user', JSON.stringify(loggedInUser))

                        this.getUserSettings(user.UserID, user.LoginToken).then(resp => {
                            // console.log('Logged in user, loading settings, userID: ' + user.UserID + ', settings: ' + JSON.stringify(resp.data))
                            this.$store.commit('setAuthUserSettings', resp.data)
                        })

                        this.showModal()
                    }
                    else {
                        // user was found but password didn't match, the only new value returned in this case is Hint if it exists
                        this.loginFailed = true
                        this.hint = user.Hint
                        this.loginFailedReason = 'password is incorrect.'
                        this.showForgotPassword = true
                    }
                }
                else if (resp.status >= 400 && resp.status < 500) {
                    this.loginFailed = true
                    this.loginFailedReason = 'No account exists for this email address.'
                }
                else {
                    this.loginFailed = true
                    this.loginFailedReason = 'Internet connection problem, please try again once this improves.'
                }
            })
        },
        makeForgotPasswordFormVisible(evt) {
            evt.preventDefault()
            this.showForgotPasswordForm = true
        },
        goCreatePasswordResetRequest(evt) {
            evt.preventDefault()
            this.passwordRequestSubmitAttempted = true
            let emailIsValid = this.isValidEmail
            this.passwordResetFailedError = false
            // Allow 4 attempts to submit an email before telling the user to contact us
            if (emailIsValid && !this.passwordResetMaxSubmitsReached) {
                this.submitPasswordResetRequest(this.form.email).then((resp) => {
                    this.passwordResetSubmitCount = this.passwordResetSubmitCount + 1
                    // console.log(JSON.stringify(resp))
                    if (resp.success) {
                        if (resp.data === false) {
                            this.passwordRequestSubmitSuccess = false
                            this.noUserFound = true
                        }
                    }
                    else {
                        this.passwordResetFailedError = true
                    }
                    this.passwordRequestSubmitSuccess = emailIsValid && !this.noUserFound && !this.passwordResetFailedError
                    this.passwordResetMaxSubmitsReached = this.passwordResetSubmitCount > this.passwordRequestMaxSubmits
                })
            }
        },
        goCreateAccount: function() {
            this.$router.replace({ name: 'Account' })
        },
        resetErrorFlags() {
            this.noUserFound = false
            this.passwordResetFailedError = false
        },

        entireFormValid: function() {
            return this.isValidEmail && this.isValidPassword
        },

        showHideModal: function(isShow) {
            let currentRoute = this.$router.currentRoute.name
            if (currentRoute === 'Subscription') {
                isShow ? this.$refs.inSubscribeFlowModalRef.show() : this.$refs.inSubscribeFlowModalRef.hide()
            }
            else {
                if (this.authUser.SubscriptionStatus === 'activeSingle') {
                    // let asref = this.$refs.activeSingleModalRef
                    // console.log('asref: ' + JSON.stringify(asref))
                    // console.log('props: ' + JSON.stringify(Object.getOwnPropertyNames(asref)))
                    // console.log('show: ' + JSON.stringify(asref.show))
                    // console.log('typeof show: ' + JSON.stringify(typeof asref.show))
                    // isShow ? asref.show() : asref.hide()
                    isShow ? this.$refs.activeSingleModalRef.show() : this.$refs.activeSingleModalRef.hide()
                }
                else if (this.authUser.SubscriptionStatus === 'activeRenews') {
                    isShow ? this.$refs.activeRenewsModalRef.show() : this.$refs.activeRenewsModalRef.hide()
                }
                else if (this.authUser.SubscriptionStatus === 'expired') {
                    isShow ? this.$refs.expiredModalRef.show() : this.$refs.expiredModalRef.hide()
                }
                else if (this.authUser.SubscriptionStatus === 'cancelled') {
                    isShow ? this.$refs.cancelledModalRef.show() : this.$refs.cancelledModalRef.hide()
                }
                else if (this.authUser.SubscriptionStatus === 'none') {
                    isShow ? this.$refs.noneModalRef.show() : this.$refs.noneModalRef.hide()
                }
            }
        },

        showModal: function() {
            this.showHideModal(true)
        },

        hideModal: function() {
            this.showHideModal(false)
        },
        handleModalOk: function() {
            // console.log('in handleModalOk')
            this.hideModal()
            // console.log('Previous Route Name' + this.$store.state.previousRouteName)

            if (this.authUser.SubscriptionStatus === 'expired') {
                // console.log('in handleModalOk 1')
                this.$router.push({ name: 'Subscription' })
            }
            else if (this.authUser.SubscriptionStatus === 'cancelled') {
                // console.log('in handleModalOk 2')
                this.$router.push({ name: 'Subscription' })
            }
            else if (this.authUser.SubscriptionStatus === 'none') {
                // console.log('in handleModalOk 3')
                this.$router.push({ name: 'Subscription' })
            }
            else if (!this.$store.state.previousRouteName) {
                // console.log('in handleModalOk 4')
                this.$router.push({ name: 'Home' })
            }
            else if (this.$store.state.previousRouteName === 'PasswordReset') {
                // console.log('in handleModalOk 4')
                this.$router.push({ name: 'Home' })
            }
            else if (this.$store.state.previousRouteName === '') {
                // console.log('in handleModalOk 4')
                this.$router.push({ name: 'Home' })
            }
            else {
                // console.log('in handleModalOk 5')
                this.navigateBackToPreviousPage()
            }
        },
        handleModalCancel: function() {
            this.hideModal()
            this.navigateBackToPreviousPage()
        },
        navigateBackToPreviousPage() {
            this.$router.go(-1)
        }

    },
    computed: {
        subscriptionExpirationDate: function() {
            return this.$store.state.authUser.SubscriptionExpirationDate
        },
        subscriptionExpirationDateWithFullMonth: function() {
            return this.$store.state.authUser.SubscriptionExpirationDateStrWithMonth
        },
        authUser: function() {
            return this.$store.state.authUser
        },
        subscriptionStatus: function() {
            return this.$store.state.authUser.SubscriptionStatus
        },
        inSubscribeFlow: function() {
            let prevRoute = this.$store.state.previousRouteName
            return prevRoute === 'Subscription'
        },
        isValidEmail: function() {
            return this.isValidEmailAddress(this.form.email)
        },
        isValidPassword: function() {
            let password = this.form.password.trim()
            if (password === null) {
                return false
            }
            if (password.length < 2) { // note: don't call utility.validPasswordFormat here because some older passwords could still be in effect which don't comply with that, this is checking only to make sure something got input
                return false
            }
            return true
        }
    },
    created() {
        this.scrollToTop()
    }

}
</script>
