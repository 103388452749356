<template>
    <small id="nameInputHelp" class="form-text text-muted"><slot></slot></small>
</template>

<script>

export default {
    props: {
    },
    methods: {
    }
}
</script>

<style>
</style>
