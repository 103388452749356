import axios from 'axios'
import cfg from '../config/config'
import mediaService from '../mixins/mediaService'
import urlsService from '../mixins/urlsService'
import utilityService from '../mixins/utilityService'

export default {
    methods: {

        getUrl(url) {
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPattern(id) {
            let url = cfg.config.apiBaseUrl + 'patterns/' + id
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternJson(id, isStraightThreading, isStraightTreadling, isPointThreading, isPointTreadling, isTurned, isSingleRepeat, userid, token) {
            // console.log('in GetPatternJSON')
            // console.log('id: ' + id)
            // console.log('id: ' + id)

            let url = cfg.config.apiBaseUrl + 'patterns/json/' + id + '/' + userid + '/' + token

            let params = []
            if (isStraightThreading) {
                params.push('straightThreading=true')
            }
            if (isStraightTreadling) {
                params.push('straightTreadling=true')
            }
            if (isPointThreading) {
                params.push('pointThreading=true')
            }
            if (isPointTreadling) {
                params.push('pointTreadling=true')
            }
            if (isTurned) {
                params.push('turned=true')
            }
            if (isSingleRepeat) {
                params.push('singleRepeat=true')
            }
            for (let c=0; c < params.length; c++) {
                url += (c===0) ? '?' : '&'
                url += params[c]
            }

            // console.log('getPatternJson Url: ' + url)

            // console.log(url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternProfileJson(id, isTurned, userid, token) {
            let url = cfg.config.apiBaseUrl + 'patterns/profilejson/' + id + '/' + userid + '/' + token

            let params = []

            if (isTurned) {
                params.push('turned=true')
            }

            for (let c=0; c < params.length; c++) {
                url += (c===0) ? '?' : '&'
                url += params[c]
            }

            // console.log(url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternUnitWeavesJson(id, userid, token) {
            let url = cfg.config.apiBaseUrl + 'patterns/unitsjson/' + id + '/' + userid + '/' + token

            // console.log(url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternTags(id, userid) {
            let url = cfg.config.apiBaseUrl + 'tags/patterntags/' + id + '/' + userid
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getAllTags(userid) {
            let url = cfg.config.apiBaseUrl + 'tags/' + userid
            // console.log('in getAllTags, url: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getUserBoard(boardid) {
            let url = cfg.config.apiBaseUrl + 'boards/' + boardid
            // console.log('in getUserBoard url: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getUserBoards(userid) {
            let url = cfg.config.apiBaseUrl + 'boards/user/' + userid
            // console.log('in getUserBoards url: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getAllBoards(userid) {
            let url = cfg.config.apiBaseUrl + 'boards'
            // console.log('in getUserBoards url: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        addUserBoard(boardTitle, userid, token) {
            let url = cfg.config.apiBaseUrl + 'boards'
            // console.log('in addPatternTag, url=' + url)
            return axios
                .post(url, { Title: boardTitle, UserID: userid, LoginToken: token })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        updateUserBoard(boardTitle, boardid, userid, token) {
            let url = cfg.config.apiBaseUrl + 'boards'
            // console.log('in updateUserBoard, url=' + url)
            // let dataPayload = { Title: boardTitle, BoardID: boardid, UserID: userid, LoginToken: token }
            let dataPayload = { BoardID: boardid, Title: boardTitle, UserID: userid, LoginToken: token }
            // console.log('data payload: ' + JSON.stringify(dataPayload))
            return axios
                .put(url, dataPayload)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        removeUserBoard(boardid, userid, token) {
            let url = cfg.config.apiBaseUrl + 'boards'
            // console.log('in removeUserBoard, ' boardid: ' + boardid + ' userid: ' + userid + ' token' + token)

            return axios
                // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway
                .delete(url, { data: { BoardID: boardid, UserID: userid, LoginToken: token } })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        addUserBoardPattern(boardID, patternID, userid, token) {
            let url = cfg.config.apiBaseUrl + 'boards/pattern'
            // console.log('in addUserBoardPattern, url=' + url)
            return axios
                .post(url, { BoardID: boardID, PatternID: patternID, UserID: userid, LoginToken: token })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getUserBoardPatterns(boardid, minShafts, maxShafts, maxTreadles, maxFloatLength, countToRetrieve, startingResult) {
            let url = cfg.config.apiBaseUrl + 'boards/patterns'
            url += '?boardid=' + boardid
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&resultsPerPage=' + countToRetrieve
            url += '&startingResult=' + startingResult
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        deleteUserBoardPatterns(boardid, userid, token) {
            let url = cfg.config.apiBaseUrl + 'boards/patterns'
            // console.log('in removeUserBoard, ' boardid: ' + boardid + ' userid: ' + userid + ' token' + token)

            return axios

                // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway
                .delete(url, { data: { BoardID: boardid, UserID: userid, LoginToken: token } })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        deleteUserBoardPattern(boardid, patternid, userid, token) {
            let url = cfg.config.apiBaseUrl + 'boards/pattern'
            // console.log('in removeUserBoard, ' boardid: ' + boardid + ' userid: ' + userid + ' token' + token)

            return axios

                // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway
                .delete(url, { data: { BoardID: boardid, PatternID: patternid, UserID: userid, LoginToken: token } })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getBoards(userid, startingResult, countToRetrieve, orderMode) {
            let url = cfg.config.apiBaseUrl + 'boards/search'
            url += '?userid=' + userid
            url += '&resultsPerPage=' + countToRetrieve
            url += '&startingResult=' + startingResult
            url += '&orderMode=' + orderMode
            // console.log('in getBoards, url: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getUsersWithBoards() {
            let url = cfg.config.apiBaseUrl + 'users/boards/'
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getTagsByPartialName(userid, partialName) {
            let url = cfg.config.apiBaseUrl + 'tags/partialname/' + userid + '/' + encodeURIComponent(partialName)
            // console.log('in getTagsByPartialName, url: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getTag(id) {
            let url = cfg.config.apiBaseUrl + 'tags/tag/' + id
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        addPatternTag(patternId, tagName, userid, token) {
            let url = cfg.config.apiBaseUrl + 'tags/patterntag'
            // console.log('in addPatternTag, url=' + url)
            return axios
                .post(url, { PatternID: patternId, TagName: tagName, UserID: userid, LoginToken: token })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        removePatternTag(patternId, tagid, userid, token) {
            let url = cfg.config.apiBaseUrl + 'tags/patterntag'
            // console.log('in removePatternTag, patternId: ' + patternId + ' tagid: ' + tagid + ' userid: ' + userid + ' token' + token)

            return axios

                // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway
                .delete(url, { data: { PatternID: patternId, TagID: tagid, UserID: userid, LoginToken: token } })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        deletePatternTag(tagid, userid, token) {
            let url = cfg.config.apiBaseUrl + 'tags/tag'
            // console.log('in deletePatternTag, tagid: ' + tagid + ' userid: ' + userid + ' token' + token)

            let dataPayload = { TagID: tagid, UserID: userid, LoginToken: token }
            return axios
                // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway
                .delete(url, { data: dataPayload })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        renamePatternTag(tagid, newName, userid, token) {
            let url = cfg.config.apiBaseUrl + 'tags/tag'
            console.log('in renamePatternTag, tagid: ' + tagid + ' userid: ' + userid + ' token' + token)
            let dataPayload = { TagID: tagid, TagName: newName, UserID: userid, LoginToken: token }
            console.log('dataPayload: ' + JSON.stringify(dataPayload))
            return axios
                .put(url, dataPayload)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        combinePatternTags(survivorTagID, deleteTagID, userid, token) {
            let url = cfg.config.apiBaseUrl + 'tags/combine'
            let dataPayload = { TagID: survivorTagID, CombineFromTagID: deleteTagID, UserID: userid, LoginToken: token }
            console.log('in combinePatternTags, dataPayload: ' + JSON.stringify(dataPayload))

            return axios
                // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway
                .put(url, dataPayload)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        addPatternLike(patternId, userid, token) {
            let url = cfg.config.apiBaseUrl + 'likes/patternlike'
            // console.log('in addPatternLike, url=' + url)
            return axios
                .post(url, { PatternID: patternId, UserID: userid, LoginToken: token })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        removePatternLike(patternId, userid, token) {
            let url = cfg.config.apiBaseUrl + 'likes/patternlike'
            // console.log('in removePatternLike, patternId: ' + patternId + ' userid: ' + userid + ' token' + token)
            // console.log('in removePatternLike, url=' + url + ' payload: ' + JSON.stringify({ data: { PatternID: patternId, UserID: userid, LoginToken: token } }))
            return axios

            // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway

                .delete(url, { data: { PatternID: patternId, UserID: userid, LoginToken: token } })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternLikes(id, userid) {
            let url = cfg.config.apiBaseUrl + 'likes/patternlike/' + id + '/' + userid
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternComments(patternid) {
            let url = cfg.config.apiBaseUrl + 'comments/pattern/' + patternid
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        addPatternComment(patternid, commentText, userid, token) {
            let url = cfg.config.apiBaseUrl + 'comments/pattern'
            // console.log('in addPatternTag, url=' + url)
            return axios
                .post(url, { PatternID: patternid, CommentText: commentText, UserID: userid, LoginToken: token })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        removePatternComment(commentid, userid, token) {
            let url = cfg.config.apiBaseUrl + 'comments/pattern'
            return axios

            // note: axios doesn't send a body on delete, but you can use the config.data object like this to send one anyway

                .delete(url, { data: { CommentID: commentid, UserID: userid, LoginToken: token } })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternCollectionPosition(id, bookid) {
            let url = cfg.config.apiBaseUrl + 'patterns/collectionposition/' + id + '/' + bookid
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternCollectionPositionFiltered(id, bookid, minShafts, maxShafts, maxTreadles, maxFloatLength) {
            let url = cfg.config.apiBaseUrl + 'patterns/collectionpositionfiltered/' + id + '/' + bookid + '/' + minShafts + '/' + maxShafts + '/' + maxTreadles + '/' + maxFloatLength
            // console.log('getPatternCollectionPositionFiltered: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    // console.log('getPatternCollectionFilteredResponse: ' + JSON.stringify(resp))
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    // console.log('getPatternCollectionFilteredErr: ' + JSON.stringify(err))
                    return this.packageAxiosResponse(err, null)
                })
        },

        getRandomPatterns(countToRetrieve, minShafts, maxShafts, maxTreadles, maxFloatLength, maxWarpThreads, maxWeftThreads) {
            let url = cfg.config.apiBaseUrl + 'patterns/random'
            url += '?countToRetrieve=' + countToRetrieve
            url += '&maxWarpThreads=' + maxWarpThreads
            url += '&maxWeftThreads=' + maxWeftThreads
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getPatternsCount() {
            let url = cfg.config.apiBaseUrl + 'patterns/count'
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getMatchingThreadingPatternsCount(patternId, threadingHash, isTurned, isStraightened, isPointed, maxTreadles, maxFloatLength, exactMatch) {
            let url = cfg.config.apiBaseUrl + 'patterns/threadingcount'
            url += '?patternId=' + patternId
            url += '&threadingHash=' + threadingHash
            url += '&isTurned=' + isTurned
            url += '&isStraightened=' + isStraightened
            url += '&isPointed=' + isPointed
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&exactMatch='
            url += exactMatch ? 'true' : 'false'

            console.log('getMatchingThreadingPatternsCount url:' + url)

            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getMatchingThreadingPatterns(patternId, threadingHash, isTurned, isStraightened, isPointed, maxTreadles, maxFloatLength, exactMatch, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/threading'
            url += '?patternId=' + patternId
            url += '&threadingHash=' + threadingHash
            url += '&isTurned=' + isTurned
            url += '&isStraightened=' + isStraightened
            url += '&isPointed=' + isPointed
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&exactMatch='
            url += exactMatch ? 'true' : 'false'

            console.log('getMatchingThreadingPatterns url:' + url)

            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getMatchingTieupPatternsCount(patternId, tieupHash, threadingVariantName, treadlingVariantName, isTurned, maxTreadles, maxFloatLength) {
            let url = cfg.config.apiBaseUrl + 'patterns/tieupcount'
            url += '?patternId=' + patternId
            url += '&tieupHash=' + tieupHash
            url += '&threadingVariantName=' + threadingVariantName // must be 'n' for native, or 's' for straight, or 'p' for point
            url += '&treadlingVariantName=' + treadlingVariantName // must be 'n' for native, or 's' for straight, or 'p' for point
            url += '&isTurned=' + (isTurned ? 'true' : 'false')
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength

            console.log('getMatchingTieupPatternsCount url:' + url)

            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getMatchingTieupPatterns(patternId, tieupHash, threadingVariantName, treadlingVariantName, isTurned, maxTreadles, maxFloatLength, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/tieup'
            url += '?patternId=' + patternId
            url += '&tieupHash=' + tieupHash
            url += '&threadingVariantName=' + threadingVariantName // must be 'n' for native, or 's' for straight, or 'p' for point
            url += '&treadlingVariantName=' + treadlingVariantName // must be 'n' for native, or 's' for straight, or 'p' for point
            url += '&isTurned=' + (isTurned ? 'true' : 'false')
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            // console.log('tieup url: ' + url)

            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getMatchingPatterns(userId, bookId, keyword, categoryIDsCDL, tagIDsCDL, minShafts, maxShafts, maxTreadles, maxFloatLength, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/search'
            url += '?bookId=' + bookId
            url += '&userID=' + userId
            url += '&keyword=' + encodeURIComponent(keyword)
            url += '&categoryIDsCDL=' + encodeURIComponent(categoryIDsCDL)
            url += '&tagIDsCDL=' + encodeURIComponent(tagIDsCDL)
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult

            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getMostPopularPatterns(countToRetrieve, minShafts, maxShafts, maxTreadles, maxFloatLength, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/popular'
            url += '?countToRetrieve=' + countToRetrieve
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getLikedPatterns(userId, minShafts, maxShafts, maxTreadles, maxFloatLength, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/liked'
            url += '?UserId=' + userId
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getBlockSizePatterns(verticalEndsPerBlock, horizontalEndsPerBlock, minShafts, maxShafts, maxTreadles, maxFloatLength, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/search'
            url += '?blockVerticalEnds=' + verticalEndsPerBlock
            url += '&blockHorizontalEnds=' + horizontalEndsPerBlock
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult

            // console.log('in getBlockSizePatterns, url: ' + url)

            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getBlockDesignedPatterns(minShafts, maxShafts, maxTreadles, maxFloatLength, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/search'
            url += '?requireBlockDesignedDraft=true'
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult

            // console.log('in getBlockDesignedPatterns, url: ' + url)

            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getNewestPatterns(countToRetrieve, minShafts, maxShafts, maxTreadles, maxFloatLength, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'patterns/newest'
            url += '?countToRetrieve=' + countToRetrieve
            url += '&minShafts=' + minShafts
            url += '&maxShafts=' + maxShafts
            url += '&maxTreadles=' + maxTreadles
            url += '&maxFloatLength=' + maxFloatLength
            url += '&resultsPerPage=' + resultsPerPage
            url += '&startingResult=' + startingResult
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // paged pattern functions expect a startingResult that is 1-based, but maintained in Vue component state as 0-based
        getClipboardPatterns(userId, countToRetrieve, startingResult) {
            let url = cfg.config.apiBaseUrl + 'clipboards/clipboard'
            url += '?userId=' + userId
            url += '&resultsPerPage=' + countToRetrieve
            url += '&startingResult=' + startingResult
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getDraftBookProducts(id) {
            let url = cfg.config.apiBaseUrl + 'storeproducts/book/' + id
            return axios
                .get(url)
                .then(resp => {
                    for (let c = 0; c < resp.data.length; c++) {
                        let item = resp.data[c]
                        let itemStoreUrl = mediaService.methods.storeProductUrl(item.StoreUrlToken)
                        item.ProductUrl = itemStoreUrl
                        item.ImageUrl = mediaService.methods.storeProductImageUrl(item.SKU)
                    }
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postLogin(email, password) {
            let url = cfg.config.apiBaseUrl + 'users/login'
            return axios
                .post(url, { Email: email, Password: password })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postUser(email, password, name, alias, hint, isConfidential, preferredTieupOrientation, captchaToken) {
            let url = cfg.config.apiBaseUrl + 'users/user'
            return axios
                .post(url, { Email: email, Password: password, Name: name, Alias: alias, Hint: hint, IsConfidential: isConfidential, PreferredTieupOrientation: preferredTieupOrientation, CaptchaToken: captchaToken })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        putUser(userId, email, password, name, alias, hint, isConfidential, preferredTieupOrientation, loginToken, captchaToken) {
            let url = cfg.config.apiBaseUrl + 'users/user'
            return axios
                .put(url, { UserID: userId, Email: email, Password: password, Name: name, Alias: alias, Hint: hint, IsConfidential: isConfidential, PreferredTieupOrientation: preferredTieupOrientation, LoginToken: loginToken, CaptchaToken: captchaToken })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        // note: this is being deprecated in place of getUserSettings / putUserSettings below
        putUserPreferences(userId, preferredTieupOrientation, loginToken) {
            let url = cfg.config.apiBaseUrl + 'users/preferences'
            return axios
                .put(url, { UserID: userId, PreferredTieupOrientation: preferredTieupOrientation, LoginToken: loginToken })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        putUserSettings(userId, settings, loginToken) {
            let url = cfg.config.apiBaseUrl + 'users/settings'
            let userSettingsParams = settings
            userSettingsParams.UserID = userId
            userSettingsParams.LoginToken = loginToken
            return axios
                .put(url, userSettingsParams)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getUserSettings(userId, loginToken) {
            let url = cfg.config.apiBaseUrl + 'users/settings/' + userId + '/' + loginToken
            // console.log('getUserSettings: ' + url)
            return axios
                .get(url)
                .then(resp => {
                    // console.log('getUserSettingsResponse: ' + JSON.stringify(resp))
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    // console.log('getUserSettingsErr: ' + JSON.stringify(err))
                    return this.packageAxiosResponse(err, null)
                })
        },

        validateUser(user) {
            // console.log('validating user')
            let url = cfg.config.apiBaseUrl + 'users/validate'
            return axios
                .post(url, { Email: '', Password: '', Name: '', Alias: '', Hint: '', IsConfidential: true, PreferredTieupOrientation: 'tl', UserID: user.UserID, LoginToken: user.LoginToken })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },
        updatePasswordResetRequest(guid, newPassword) {
            let url = cfg.config.apiBaseUrl + 'users/passwordreset'
            return axios
                .put(url, { ResetRequestID: guid, NewPassword: newPassword, Email: '' })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp)
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },
        getPasswordResetRequest(guid) {
            let url = cfg.config.apiBaseUrl + 'users/passwordreset/' + guid
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp)
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },
        submitPasswordResetRequest(email) {
            let url = cfg.config.apiBaseUrl + 'users/passwordreset'
            return axios
                .post(url, { ResetRequestID: '', NewPassword: '', Email: email })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp)
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        contactUs(email, message) {
            let url = cfg.config.apiBaseUrl + 'users/contact'
            return axios
                .post(url, { Email: email, Message: message })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getBraintreeClientToken(userId, token) {
            let url = cfg.config.apiBaseUrl + 'commerce/btclienttoken/' + userId + '/' + token
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        removeSubscription(userID, token) {
            let url = cfg.config.apiBaseUrl + 'commerce/removesubscription/' + userID + '/' + token
            return axios.post(url, {})
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        addOrUpdateSubscription(userID, token, subscriptionType, paymentNonce) {
            let url = cfg.config.apiBaseUrl + 'commerce/addorupdatesubscription/' + userID + '/' + token + '/' + subscriptionType + '/' + paymentNonce
            return axios.post(url, {})
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        addOrUpdateSubscriptionDisc(userID, token, subscriptionType, discountPlanID, paymentNonce) {
            let url = cfg.config.apiBaseUrl + 'commerce/addorupdatesubscriptiondisc/' + userID + '/' + token + '/' + subscriptionType + '/' + discountPlanID + '/' + paymentNonce
            return axios.post(url, {})
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getCollection(id) {
            let url = cfg.config.apiBaseUrl + 'patternbooks/' + JSON.stringify(id)
            // console.log('in getCollection(id)) id: ' + JSON.stringify(id))
            // console.log('url: ' + JSON.stringify(url))
            return axios.get(url)
                .then(resp => {
                    let coverImageUrl = mediaService.methods.getCollectionCoverImageUrl(resp.data)
                    resp.data.CoverImageUrl = coverImageUrl
                    let collectionFirstPageUrl = urlsService.methods.buildCollectionPageUrl(resp.data, 0)
                    resp.data.FirstPageUrl = collectionFirstPageUrl
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getCollectionsList() {
            let url = cfg.config.apiBaseUrl + 'patternbooks'
            // console.log('in getCollectionsList')
            return axios.get(url)
                .then(resp => {
                    let collectionsList = resp.data
                    for (let c = 0; c < collectionsList.length; c++) {
                        let collection = collectionsList[c]
                        let coverImageUrl = mediaService.methods.getCollectionCoverImageUrl(collection)
                        collection.CoverImageUrl = coverImageUrl
                        let collectionFirstPageUrl = urlsService.methods.buildCollectionPageUrl(collection, 0)
                        collection.FirstPageUrl = collectionFirstPageUrl
                    }
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getCategoriesList() {
            let url = cfg.config.apiBaseUrl + 'categories'
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        packageAxiosResponse(err, responseData) {
            if (!err) {
                return {
                    status: 200,
                    success: true,
                    data: responseData.data
                }
            }
            else {
                return {
                    status: (err && err.response && err.response.status) ? err.response.status : 0, // no status means network error
                    success: false,
                    data: (err && err.response && err.response.data) ? err.response.data : 0 // no status means network error
                }
            }
        },

        postClipboardPattern(userId, patternId, loginToken) {
            let url = cfg.config.apiBaseUrl + 'clipboards/pattern'
            return axios
                .post(url, { UserID: userId, PatternID: patternId, Token: loginToken })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        deleteClipboardPattern(userId, patternId, loginToken) {
            let url = cfg.config.apiBaseUrl + 'clipboards/pattern?UserID=' + userId + '&PatternID=' + patternId + '&Token=' + loginToken
            return axios
                .delete(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        clearClipboard(userId, loginToken) {
            let url = cfg.config.apiBaseUrl + 'clipboards/clipboard/clear?UserID=' + userId + '&PatternID=0' + '&Token=' + loginToken
            return axios
                .delete(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postAnalytics(userId, daItemFileId, patternId, isDownload) {
            let url = cfg.config.apiBaseUrl + 'analytics/record'
            return axios
                .post(url, { UserId: userId, PatternId: patternId, DAItemFileId: daItemFileId, IsDownload: isDownload })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getWIFDownloadUrl(userId, loginToken, patternId, isTurned, isStraightThreading, isStraightTreadling, isPointThreading, isPointTreadling, isLiftplan) {
            let url = cfg.config.apiBaseUrl + 'patterns/wif/'
            url += '?UserID=' + userId
            url += '&Token=' + loginToken
            url += '&PatternID=' + patternId
            url += '&TurnedWIF=' + (isTurned ? 'true' : 'false')
            url += '&StraightThreadingWIF=' + (isStraightThreading ? 'true' : 'false')
            url += '&StraightTreadlingWIF=' + (isStraightTreadling ? 'true' : 'false')
            url += '&PointThreadingWIF=' + (isPointThreading ? 'true' : 'false')
            url += '&PointTreadlingWIF=' + (isPointTreadling ? 'true' : 'false')
            url += '&LiftplanWIF=' + (isLiftplan ? 'true' : 'false')
            return url
        },

        getTempWIFDownloadUrl(userId, loginToken) {
            let url = cfg.config.apiBaseUrl + 'patterns/temporary'
            url += '?UserID=' + userId
            url += '&LoginToken=' + loginToken
            return url
        },

        getDAAuthors() {
            return this.getDAFilterList('daauthors')
        },

        getDATopics() {
            return this.getDAFilterList('datopics')
        },

        getDAPublications() {
            return this.getDAFilterList('dapubs')
        },

        getDALanguages() {
            return this.getDAFilterList('dalanguages')
        },

        getDAFilterList(listType) {
            let url = cfg.config.apiBaseUrl + listType
            return axios
                .get(url)
                .then(resp => {
                    let list = resp.data
                    for (let c = 0; c < list.length; c++) {
                        let item = list[c]
                        let truncatedDisplayName = utilityService.methods.truncate(item.Name, 30)
                        item.displayName = truncatedDisplayName
                    }
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getMatchingDAItems(keywords, authorId, pubId, topicId, language, typesCDL, orderByFieldname, startingResult, resultsPerPage) {
            let url = cfg.config.apiBaseUrl + 'daitems/search'
            url += '?keywords=' + keywords
            url += '&authorId=' + authorId
            url += '&pubId=' + pubId
            url += '&topicId=' + topicId
            url += '&language=' + language
            url += '&typesCDL=' + typesCDL
            url += '&orderByFieldname=' + orderByFieldname
            url += '&startingResult=' + startingResult
            url += '&resultsPerPage=' + resultsPerPage

            return axios
                .get(url)
                .then(resp => {
                    let list = resp.data
                    for (let c = 0; c < list.DAItemsList.length; c++) {
                        let item = list.DAItemsList[c]
                        item.SEOName = item.Title.trim()
                        if (item.AuthorName && item.AuthorName.trim().length > 0) {
                            item.SEOName += ' ' + item.AuthorName.trim()
                        }
                        if (item.PubName && item.PubName.trim().length > 0) {
                            item.SEOName += ' ' + item.PubName.trim()
                        }
                    }
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getDAItem(id) {
            let url = cfg.config.apiBaseUrl + 'daitems/' + id
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getDAItemFiles(id) {
            let url = cfg.config.apiBaseUrl + 'daitemfiles/item/' + id
            return axios
                .get(url)
                .then(resp => {
                    let list = resp.data
                    for (let c = 0; c < list.length; c++) {
                        let item = list[c]
                        let fileDownloadUrl = mediaService.methods.archiveFileUrl(item)
                        item.Url = fileDownloadUrl
                    }
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getDAItemProducts(id) {
            let url = cfg.config.apiBaseUrl + 'storeproducts/daitem/' + id
            return axios
                .get(url)
                .then(resp => {
                    let list = resp.data
                    for (let c=0; c<list.length; c++) {
                        let item = list[c]
                        let itemStoreUrl = mediaService.methods.storeProductUrl(item.StoreUrlToken)
                        item.ProductUrl = itemStoreUrl
                        item.ImageUrl = mediaService.methods.storeProductImageUrl(item.SKU)
                    }
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postPattern(fileData, userId, token) {
            let url = cfg.config.apiBaseUrl + 'patterns/upload/'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .post(url, { WIFData: fileData, UserId: userId, LoginToken: token })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postWIFToJSON(fileData) {
            let url = cfg.config.apiBaseUrl + 'patterns/wiftojson/'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .post(url, { WIFData: fileData })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postJSONToWIF(simpleDraftObj) {
            let url = cfg.config.apiBaseUrl + 'patterns/jsontowif/'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .post(url, { JSON: JSON.stringify(simpleDraftObj) })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postJSONToThreadingHash(simpleDraftObj) {
            let url = cfg.config.apiBaseUrl + 'patterns/jsontothreadinghash/'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .post(url, { JSON: JSON.stringify(simpleDraftObj) })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        postJSONToTieupHash(simpleDraftObj) {
            let url = cfg.config.apiBaseUrl + 'patterns/jsontotieuphash/'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .post(url, { JSON: JSON.stringify(simpleDraftObj) })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getAllowedDraftHashesJSON() {
            let url = 'https://s3.amazonaws.com/kb.public.items/color-editor-samples/allowed-drafts-list.json'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        colorPattern(fileData, userId, token, warpColorRGB, weftColorRGB, isWarp) {
            let url = cfg.config.apiBaseUrl + 'patterns/color/'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .post(url, { WIFData: fileData, UserId: userId, LoginToken: token, WarpColorRGB: warpColorRGB, WeftColorRGB: weftColorRGB, IsWarp: isWarp })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        colorPatternReplace(fileData, userId, token, searchColorRGB, replaceColorRGB, replaceWarp, replaceWeft) {
            let url = cfg.config.apiBaseUrl + 'patterns/colorreplace/'
            // see http://stackoverflow.com/questions/13963022/angularjs-how-to-implement-a-simple-file-upload-with-multipart-form
            return axios
                .post(url, { WIFData: fileData, UserId: userId, LoginToken: token, SearchColorRGB: searchColorRGB, ReplaceColorRGB: replaceColorRGB, ReplaceWarp: replaceWarp, ReplaceWeft: replaceWeft })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getTempDraftImageUrl(userId, cellPixelSize, token, drawdownOnly, includeDividingLines) {
            let d = new Date()
            let salt = d.getTime()
            let url = cfg.config.apiBaseUrl + 'patterns/image/'
            url += '?UserId=' + userId
            url += '&LoginToken=' + token
            url += '&CellPixelSize=' + cellPixelSize
            url += '&Salt=' + salt // prevent this image from being cached for multiple uploads since the url would be the same otherwise
            if (drawdownOnly) {
                url += '&drawdownOnly=true'
            }
            if (!includeDividingLines) {
                url += '&omitDividingLines=true'
            }
            return url
        },

        postSaveUploadedPattern(userId, token, title, areaOfOrigin) {
            let url = cfg.config.apiBaseUrl + 'patterns/upload/save/'
            let visitorContributedPatternsBookId = 12
            let autoAssignDisplayOrderToPatternID = 0
            return axios
                .post(url, { UserId: userId, LoginToken: token, Title: title, AreaOfOrigin: areaOfOrigin, BookId: visitorContributedPatternsBookId, DisplayOrder: autoAssignDisplayOrderToPatternID, IsActive: true })
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        },

        getAllUnitWeaves() {
            let url = cfg.config.apiBaseUrl + 'unitweaves/all'
            return axios
                .get(url)
                .then(resp => {
                    return this.packageAxiosResponse(null, resp) // returning a value inside .then returns another promise with that value which can then be chained with another .then()
                })
                .catch(err => {
                    return this.packageAxiosResponse(err, null)
                })
        }

    }
}
