<template>
    <li>
        <router-link v-if='to' :class='getClassnames()' :to='to'><slot></slot></router-link>
        <span v-if='!to' :class='getClassnames()'><slot></slot></span>
    </li>
</template>

<script>

export default {
    props: {
        to: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getClassnames() {
            let classnames = 'dropdown-item pointerCursor'
            if (this.disabled) {
                classnames += ' disabled'
            }
            return classnames
        }
    }
}
</script>

<style>
    .pointerCursor {
        cursor: pointer;
    }
</style>
