<template>
    <form class="needs-validation" novalidate>
        <slot></slot>
    </form>
</template>

<script>

export default {
    props: {
    },
    methods: {
    }
}
</script>

<style>
</style>
