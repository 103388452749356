<template>
    <div style='display: inline-block'>
        <button :id="domId" type="button" :style='getButtonStyle()' :class="getButtonClassName()" data-bs-toggle="dropdown" aria-expanded="false">
        {{ text }}
        </button>
        <ul class="dropdown-menu" :aria-labelledby="domId">
        <slot></slot>
        </ul>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

export default {
    props: {
        ariaLabel: {
            type: String,
            default: 'Button group with nested dropdown'
        },
        variant: {
            type: String,
            default: 'primary'
        },
        text: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        },
        valign: {
            type: String,
            default: ''
        }
    },
    methods: {
        getButtonClassName() {
            let c = 'btn dropdown-toggle btn-' + this.variant
            if (this.size !== '') {
                c+= ' btn-' + this.size
            }
            return c
        },
        getButtonStyle() {
            if (this.valign === '') {
                return ''
            }
            else {
                return 'vertical-align: ' + this.valign
            }
        }
    },
    data() {
        return {
            // this is the data model for the form
            domId: null
        }
    },
    created() {
        this.domId = 'dropdown-' + uuidv4()
    }
}
</script>

<style>
</style>
