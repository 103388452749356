<template>
    <div class='no-padding no-margin'>
        <kb-container fluid class='no-padding no-margin'>
            <hw-navbar v-if='showHeaderFooter'></hw-navbar>
        </kb-container>
        <kb-container @click='collapseHamburgerMenu()' :style='contentRegionTopStyle' class='content-region top-padding bottom-padding' fluid>
            <router-view/>
            <hw-footer v-if='showHeaderFooter'/>
        </kb-container>
    </div>
</template>

<script>
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import DataService from './mixins/dataService'
import UtilityService from './mixins/utilityService'

export default {
    mixins: [DataService, UtilityService],
    name: 'app',
    components: {
        'hw-navbar': NavBar,
        'hw-footer': Footer
    },
    created: function() {
        if (this.authUser.UserID === 0) {
            let userString = window.localStorage.getItem('user')
            if (userString) {
                let user = JSON.parse(userString)
                if (user.UserID) {
                    this.validateUser(user).then(resp => {
                        let validatedUser = resp.data
                        if (validatedUser) {
                            this.$store.commit('setAuthUser', validatedUser)

                            let loggedInUser = { UserID: validatedUser.UserID, LoginToken: validatedUser.LoginToken }
                            window.localStorage.setItem('user', JSON.stringify(loggedInUser)) // make sure only these two fields are stored, remove any previous others

                            this.getUserSettings(user.UserID, user.LoginToken).then(resp => {
                                // console.log('Returning user, loading settings, userID: ' + user.UserID + ', settings: ' + JSON.stringify(resp.data))
                                this.$store.commit('setAuthUserSettings', resp.data)
                            })
                        }
                        else {
                            window.localStorage.removeItem('user')
                            this.$store.commit('clearAuthUser')
                        }
                    })
                }
            }
        }
    },
    computed: {
        authUser: function() {
            return this.$store.state.authUser
        },
        authSettings: function() {
            return this.$store.state.authUserSettings
        },
        showLogin: function() {
            return this.$store.state.showLogin
        },
        pageTitle() {
            return this.$store.state.head.title
        },
        showHeaderFooter: function() {
            return this.$store.state.showHeaderFooter
        },
        contentRegionTopStyle: function() {
            return this.showHeaderFooter ? 'padding-top: 2.5rem' : 'padding-top: 0'
        }
    },
    watch: {
        pageTitle() {
            if (!this.$isServer) {
                document.title = 'Handweaving.net: ' + this.$store.state.head.title
            }
        }
    }
}
</script>
