// methods here build URLs for site pages

export default {
    methods: {
        buildDraftDetailUrl(draft) {
            let url = '/draft-detail/'
            url += draft.PatternID
            if (draft.DisplayTitle) {
                url += '/'
                url += this.buildDraftDetailDisplayTitle(draft)
            }
            return url
        },

        buildDraftRepeatsUrl() {
            let url = '/draft-repeats'
            return url
        },

        buildDraftArrangerUrl() {
            let url = '/draft-arranger'
            return url
        },

        buildDraftDetailDisplayTitle(draft) {
            return encodeURIComponent(this.sanitizeUrlSegment(draft.DisplayTitle))
        },

        buildDraftProfileUrl() {
            let url = '/profile'
            return url
        },

        buildDraftAmalgamationUrl() {
            let url = '/amalgamation'
            return url
        },

        buildCollectionPageUrl(collection, collectionPageNumber) {
            let url = '/collection-drafts/collection/'
            url += collection.BookID + '/'
            url += encodeURIComponent(this.sanitizeUrlSegment(collection.TitleWithAuthors)) + '?page='
            url += collectionPageNumber
            return url
        },

        buildMatchingThreadingsCollectionUrl(patternId, threadingHash, isTurned, isStraightened, isPointed) {
            let url = '/collection-drafts/threading/'
            url += '?patternId=' + patternId
            url += '&threadingHash=' + threadingHash
            url += '&isTurned=' + (isTurned ? '1' : '0')
            url += '&isStraightened=' + (isStraightened ? '1' : '0')
            url += '&isPointed=' + (isPointed ? '1' : '0')
            url += '&page=0'
            return url
        },

        buildMatchingTieupsCollectionUrl(patternId, tieupHash, threadingVariantName, treadlingVariantName, isTurned) {
            let url = '/collection-drafts/tieup/'
            url += '?patternId=' + patternId
            url += '&tieupHash=' + tieupHash
            url += '&threadingVariantName=' + threadingVariantName
            url += '&treadlingVariantName=' + treadlingVariantName
            url += '&isTurned=' + (isTurned ? '1' : '0')
            url += '&page=0'
            return url
        },

        buildMatchingTagsCollectionUrl(tagId, tagName, searchType) {
            let url = '/collection-drafts/' + searchType + '/' + tagId + '/'
            url += encodeURIComponent(this.sanitizeUrlSegment(tagName))
            url += '?page=0'
            return url
        },

        buildEditDraftUrl(isEditAndColor) {
            let url = isEditAndColor ? '/draft-editor?edit=true' : '/draft-editor'
            return url
        },

        buildDocumentDetailUrl(document) {
            let url = '/document-detail/'
            // console.log('buildDocumentDetailUrl, document: ' + JSON.stringify(document))
            url += document.ItemID + '/'
            url += encodeURIComponent(this.sanitizeUrlSegment(document.SEOName))
            return url
        },

        buildDocumentSearchTypeUrl(searchType, itemId, urlTitle) {
            let url = '/document-search/' + searchType + '/' + itemId + '/' + this.sanitizeUrlSegment(urlTitle)
            return url
        },

        sanitizeUrlSegment(url) {
            let lowercase = url.toLowerCase()

            // replace common accents for neat urls
            let r = lowercase.replace(/[àáâãäå]/g, 'a')
            r = r.replace(/æ/g, 'ae')
            r = r.replace(/ç/g, 'c')
            r = r.replace(/[èéêë]/g, 'e')
            r = r.replace(/[ìíîï]/g, 'i')
            r = r.replace(/ñ/g, 'n')
            r = r.replace(/[òóôõö]/g, 'o')
            r = r.replace(/œ/g, 'oe')
            r = r.replace(/[ùúûü]/g, 'u')
            r = r.replace(/[ýÿ]/g, 'y')

            // replace other non-alpha characters for neat seo token urls
            let nosquarebrackets = r.replace(/[\[\]']+/g, '') // eslint-disable-line no-useless-escape
            let alphaNumericsOnly=nosquarebrackets.replace(/[^a-z0-9]/gi, ' ')
            let nodoublespaces = alphaNumericsOnly.replace(/\s{2,}/g, ' ')
            nodoublespaces = nodoublespaces.replace(/\s{2,}/g, ' ')
            let nospaces = nodoublespaces.replace(/\s/g, '-')
            return window.encodeURIComponent(nospaces)
        }
    }
}
