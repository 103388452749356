export default {
    methods: {
        isValidEmailAddress(email) {
            // this method borrowed from the Chromium source code...
            let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line no-useless-escape
            return re.test(email)
        },
        guidIsEmpty(g) {
            return !g || g === '' || g === '00000000-0000-0000-0000-000000000000'
        },
        validPasswordFormat(password) {
            return (password && password.trim().length > 6) // pretty minimal for now, maybe change this later for new passwords and updates?
        },
        scrollToTop() {
            window.scrollTo(0, 0)
        },
        debounceInput(func, wait, immediate) {
            let context = this
            let args = arguments
            window.clearTimeout(this.timerHandle)
            this.timerHandle = window.setTimeout(() => {
                this.timerHandle = null
                if (!immediate) {
                    func.apply(context, args)
                }
            }, wait)
            if (immediate && !this.timerHandle) {
                func.apply(context, args)
            }
        },
        truncate(string, maxLength) {
            if (string.length <= maxLength) {
                return string
            }
            else {
                return string.substring(0, maxLength) + '...'
            }
        },

        findOptionWithValue(optionsList, value) {
            for (let c=0; c < optionsList.length; c++) {
                if (optionsList[c].value === value) {
                    return optionsList[c]
                }
            }
            return null
        },

        isValidPositiveOrZeroNumber(n) {
            return (n && n >= 0)
        },

        hasAtLeastOneAlphaNumericChar(s) {
            return s.match(/[a-zA-Z0-9]/g)
        },
        currentDeviceIsMobile() {
            return (typeof window.orientation !== 'undefined')
        },
        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                let r = Math.random() * 16 | 0
                let v = c === 'x' ? r : (r & 0x3 | 0x8)
                return v.toString(16)
            })
        },
        hexToRGBColors(hex) {
            let bigint = parseInt(hex.hex.substr(1), 16)
            let r = (bigint >> 16) & 255
            let g = (bigint >> 8) & 255
            let b = bigint & 255
            let colorsObj = { R: r, G: g, B: b }
            // console.log('hex: ' + hex.hex.substr(1) + ', RGB: ' + JSON.stringify(colorsObj))
            return colorsObj
        },
        componentToHex(c) {
            let hex = c.toString(16)
            return hex.length === 1 ? '0' + hex : hex
        },
        RGBToHex(r, g, b) {
            return '#' + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b)
        },
        equalRGBColors(firstColor, secondColor) {
            return (firstColor.R === secondColor.R) && (firstColor.G === secondColor.G) && (firstColor.B === secondColor.B)
        },
        rotate2dArray(arr) {
            // console.log('in rotate2dArray, source arr: ')
            // this.print2dArray(arr)
            let rows = arr.length
            let cols = arr[0].length
            // console.log('in rotate2dArray, rows: ' + JSON.stringify(rows))
            // console.log('in rotate2dArray, cols: ' + JSON.stringify(cols))
            let destRows = this.buildEmpty2DArray(cols, rows)
            for (let destRow = 0; destRow < cols; destRow++) {
                for (let destCol=0; destCol < rows; destCol++) {
                    destRows[destRow][destCol] = arr[destCol][destRow]
                }
            }
            // console.log('in rotate2dArray, result arr: ')
            // this.print2dArray(destRows)
            return destRows
        },

        buildEmpty2DArray(height, width) {
            let a = []
            for (let row=0; row < height; row++) {
                let rowArray = new Array(width)
                a.push(rowArray)
            }
            return a
        },

        print2dArray(t) {
            let tStr = '\n'
            for (let row = 0; row < t.length; row++) {
                for (let col=0; col < t[0].length; col++) {
                    if (t[row][col]) {
                        tStr += '1 '
                    }
                    else {
                        tStr += '0 '
                    }
                }
                tStr += '\n'
            }
            this.timelog(tStr)
        },

        equal2dArrayRows(arr, rone, rtwo) {
            let numberOfColumns = arr[0].length
            // this.print2dArray(arr)
            for (let c=0; c < numberOfColumns; c++) {
                let first = arr[rone][c]
                if (!first) {
                    first = false
                }
                else first = true
                let second = arr[rtwo][c]
                if (!second) {
                    second = false
                }
                else second = true
                // console.log('first: ' + first + ' second: ' + second)
                if (first !== second) {
                    return false
                }
            }
            return true
        },

        equalMultiple2dArrayRows(arrone, arrtwo, rone, rtwo) {
            let numberOfColumns = arrone[0].length
            // this.print2dArray(arr)
            for (let c=0; c < numberOfColumns; c++) {
                let first = arrone[rone][c]
                if (!first) {
                    first = false
                }
                else first = true
                let second = arrtwo[rtwo][c]
                if (!second) {
                    second = false
                }
                else second = true
                // console.log('first: ' + first + ' second: ' + second)
                if (first !== second) {
                    return false
                }
            }
            return true
        },

        clear2dArrayInnerArrays(a) {
            for (let row=0; row < a.length; row++) {
                let rowArray = a[row]
                for (let col=0; col < rowArray.length; col++) {
                    rowArray[col] = null
                }
            }
        },

        isEmpty2dArrayRow(a, r) {
            let colsCount = a[r].length
            for (let c=0; c < colsCount; c++) {
                if (a[r][c]) {
                    return false
                }
            }
            return true
        },

        isEmpty2dArrayCol(a, c) {
            let rowsCount = a.length
            for (let r=0; r < rowsCount; r++) {
                if (a[r][c]) {
                    return false
                }
            }
            return true
        },

        timelog(msg) {
            console.log('time: ' + Date.now().toString() + ' ' + msg)
        },

        getLeastCommonMultiple(firstNum, secondNum) {
            let lcm = firstNum * secondNum
            for (let i=1; i<= secondNum; i++) {
                if (((firstNum * i) % secondNum) === 0) {
                    return firstNum * i
                }
            }
            return lcm
        },

        collapseHamburgerMenu() {
            // also see similar code in router/index.js to collapse the hamburger for navigation routes
            // this is only used here for navigation to outside pages, target=_blank
            try {
                if (!document) {
                    // console.log('no document')
                }
                else {
                    let burger = document.getElementById('navbarSupportedContent')
                    if (!burger) {
                        // console.log('no burger')
                    }
                    else {
                        burger.classList.remove('show')
                    }
                    // console.log('burger classes: ' + JSON.stringify(burger.classList))
                }
            }
            catch (e) {
                // console.log('NavBar hamburger collapse error: ' + JSON.stringify(e))
            }
        },

        setQuerystringValue(propertyName, propertyValue) {
            let q = this.getCurrentQuerystringCopy() // vue router won't pick up changes to the original object; a copy must be made
            // console.log('in setQuerystringValue, propertyName: ' + propertyName + ', propertyValue: ' + propertyValue)
            q[propertyName] = propertyValue + ''
            // eslint-disable-next-line node/handle-callback-err
            return this.$router.replace({ query: q }).catch(err => {
                // console.log('error in setQuerystringValue: ' + JSON.stringify(err))
            })
        },

        getCurrentQuerystringCopy() {
            let q = Object.assign({}, this.$route.query) // vue router won't pick up changes to the original object; a copy must be made
            if (!q.page) {
                q.page = '0'
            }
            return q
        },

        getFilenameFromUrl(url) {
            return url.split('/').pop()
        },

        // returns a filename like hwnet-14391-profile-2022-May14-0910pm
        getBaseDownloadFilename(patternId, wifFilename, operation) {
            let fn = ''
            if (patternId > 0) {
                fn = 'hwnet-'
                fn += patternId + '-'
                if (operation && operation.length > 0) {
                    fn += operation + '-'
                }
                fn += this.currentDateString()
                return fn
            }
            else if (wifFilename && wifFilename.length > 0 && (wifFilename.indexOf('hwnet-') === 0)) { // a wif from our tools was reloaded
                let parts = wifFilename.split('-')
                if (parts.length >= 2) { // if the name is the standard format above, keep the original name and id or wif base name
                    fn = parts[0] + '-' + parts[1] + '-'
                    if (operation && operation.length > 0) {
                        fn += operation + '-'
                    }
                    fn += this.currentDateString()
                    return fn
                }
                else {
                    fn = 'hwnet-'
                    if (operation && operation.length > 0) {
                        fn += operation + '-'
                    }
                    fn += this.currentDateString()
                    return fn
                }
            }
            else { // some other wif was loaded, remove .wif from the end and build one of our filenames out of it
                let name = wifFilename.toLowerCase()
                fn = 'hwnet-'
                fn += name
                fn += '-'
                if (operation && operation.length > 0) {
                    fn += operation + '-'
                }
                fn += this.currentDateString()
                return fn
            }
        },

        currentDateString() {
            let d = new Date()
            let s = d.getFullYear()
            s += this.monthNameFromNumber(d.getMonth())
            s += this.twoDigitNumericStr(d.getDate())
            s += '-'
            let hours = d.getHours()
            // console.log('raw hours: ' + hours)
            let ampm = hours > 12 ? 'pm' : 'am'
            if (hours > 12) {
                hours -= 12
            }
            s += this.twoDigitNumericStr(hours)
            s += this.twoDigitNumericStr(d.getMinutes())
            s += ampm
            return s
        },

        monthNameFromNumber(m) {
            switch (m) {
                case 0: return 'jan'
                case 1: return 'feb'
                case 2: return 'mar'
                case 3: return 'apr'
                case 4: return 'may'
                case 5: return 'jun'
                case 6: return 'jul'
                case 7: return 'aug'
                case 8: return 'sep'
                case 9: return 'oct'
                case 10: return 'nov'
                case 11: return 'dec'
                default: return ''
            }
        },

        twoDigitNumericStr(n) {
            if (n > 10) {
                return n.toString()
            }
            else return '0' + n.toString()
        },

        alphanumericCharsOnly(str) {
            let alphaNumericsOnly = str.replace(/[^a-z0-9]/gi, '')
            return alphaNumericsOnly
        }

    },

    data() {
        return {
            timerHandle: null
        }
    }
}
