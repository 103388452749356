import { createStore } from 'vuex'
import mediaService from '../mixins/mediaService'
// TODO:  Move accessing user from local store for getting to App.vue, and for setting, to Login.vue, that should not be here!

const emptyAuthUser = {
    Alias: '',
    CreditCardExpiration: '',
    CreditCardLastFour: '',
    Email: '',
    HasCancelledSubscription: false,
    HasCurrentSubscription: false,
    HasPastDueSubscription: false,
    HasProviderSubscription: false,
    Hint: '',
    IsConfidential: true,
    LoginToken: '',
    Name: '',
    Password: '',
    PreferredTieupOrientation: 'tl',
    Provider: '',
    ProviderPaymentType: '',
    ProviderSubscriptionID: '',
    ProviderUserID: '',
    SubscriptionExpirationDate: '1/1/1900',
    SubscriptionID: 0,
    SubscriptionIsCancelled: false,
    SubscriptionPaymentDetailsPrompt: '',
    SubscriptionType: '',
    UserID: 0,
    SubscriptionStatus: 'none'
}

const emptyAuthUserSettings = {
    UserID: 0,
    Orientation: 'tr',
    ThumbnailSize: 1,
    MinShafts: 0,
    MaxShafts: 0,
    MaxTreadles: 0,
    MaxFloatLength: 0
}

const nonSubscriberDraftImageUrlFunction = (patternId, cellPixelSize, orientation, isTurned, isStraightThreading, isStraightTreadling, isPointThreading, isPointTreadling, originalHasStraightThreading, originalHasStraightTreadling, originalHasPointThreading, originalHasPointTreadling, showLiftplan, imagesExist) => mediaService.methods.draftImageUrl(patternId, cellPixelSize, orientation, isTurned, isStraightThreading, isStraightTreadling, isPointThreading, isPointTreadling, originalHasStraightThreading, originalHasStraightTreadling, originalHasPointThreading, originalHasPointTreadling, showLiftplan, imagesExist, false)

export default createStore({
    state: {
        authUser: emptyAuthUser,
        authUserSettings: emptyAuthUserSettings,
        homePagePatterns: [],
        userUnitWeaveIDs: [],
        draftImageUrlFunction: nonSubscriberDraftImageUrlFunction,
        head: {
            title: ''
        },
        previousRouteName: '',
        showHeaderFooter: true,
        discountPlanID: null,
        discountPlanMessage: null,
        enableMonthlySubscribe: true,
        threadingSearchJSON: null,
        tieupSearchJSON: null,
        editDraftJSON: null,
        editDraftSource: { PatternID: 0, WIFFilename: '', DisplayTitle: '' }
    },
    getters: {
        isAuthenticated(state) {
            return state.authUser && state.authUser.UserID > 0
        },
        isSubscriber(state) {
            // use this instead of status == activeSingle || status == activeRenews because also includes cancelled not yet expired
            return state.authUser && state.authUser.HasCurrentSubscription
        },
        showHeaderFooter(state) {
            return state.showHeaderFooter
        },
        discountPlanExists(state) {
            return state.discountPlanID && state.discountPlanID.length > 0 && state.discountPlanMessage && state.discountPlanMessage.length > 0
        },
        ThreadingSearchJSON(state) {
            return state.threadingSearchJSON
        },
        TieupSearchJSON(state) {
            return state.tieupSearchJSON
        }
    },
    mutations: {
        setAuthUser(state, newAuthUser) {
            if (newAuthUser) {
                newAuthUser.SubscriptionStatus = 'none'
                if (newAuthUser.HasCurrentSubscription) {
                    if (newAuthUser.HasProviderSubscription) {
                        newAuthUser.SubscriptionStatus = 'activeRenews'
                    }
                    else {
                        newAuthUser.SubscriptionStatus = 'activeSingle'
                    }
                }
                if (newAuthUser.HasPastDueSubscription) {
                    newAuthUser.SubscriptionStatus = 'expired'
                    state.draftImageUrlFunction = nonSubscriberDraftImageUrlFunction
                }
                else if (newAuthUser.HasCancelledSubscription) {
                    newAuthUser.SubscriptionStatus = 'cancelled'
                    state.draftImageUrlFunction = nonSubscriberDraftImageUrlFunction
                }
            }
            if (newAuthUser.HasCurrentSubscription) {
                // replace the draft image function with one that shows full drafts if the subscription is active or cancelled but not yet expired
                state.draftImageUrlFunction = (patternId, cellPixelSize, orientation, isTurned, isStraightThreading, isStraightTreadling, isPointThreading, isPointTreadling, originalHasStraightThreading, originalHasStraightTreadling, originalHasPointThreading, originalHasPointTreadling, showLiftplan, imagesExist) => mediaService.methods.draftImageUrl(patternId, cellPixelSize, orientation, isTurned, isStraightThreading, isStraightTreadling, isPointThreading, isPointTreadling, originalHasStraightThreading, originalHasStraightTreadling, originalHasPointThreading, originalHasPointTreadling, showLiftplan, imagesExist, true)
            }
            state.authUser = newAuthUser
            // console.log('new active user: ' + JSON.stringify(newAuthUser))
        },
        setAuthUserSettings(state, auSettings) {
            state.authUserSettings = auSettings
        },
        setShowHeaderFooter(state, isShow) {
            state.showHeaderFooter = isShow
        },
        setHomePagePatterns(state, hpp) {
            state.homePagePatterns = hpp
        },
        setUserUnitWeaveIDs(state, uwIds) {
            state.userUnitWeaveIDs = uwIds
        },
        clearAuthUser(state) {
            state.authUser = emptyAuthUser
            state.authUserSettings = emptyAuthUserSettings
            state.draftImageUrlFunction = nonSubscriberDraftImageUrlFunction
        },
        setDraftImageUrlFunction(state, newDraftImageUrlFunction) {
            state.draftImageUrlFunction = newDraftImageUrlFunction
        },
        setPageTitle(state, title) {
            state.head.title = title
        },
        setPreferredTieupOrientation(state, orientation) {
            state.authUserSettings.Orientation = orientation
        },
        setFilters(state, newSettings) {
            state.authUserSettings.MinShafts = newSettings.MinShafts
            state.authUserSettings.MaxShafts = newSettings.MaxShafts
            state.authUserSettings.MaxTreadles = newSettings.MaxTreadles
            state.authUserSettings.MaxFloatLength = newSettings.MaxFloatLength
        },
        setThumbnailSize(state, thumbnailSize) {
            state.authUserSettings.ThumbnailSize = thumbnailSize
        },
        setPreviousRouteName(state, from) {
            state.previousRouteName = from
        },
        setDiscountPlanID(state, planID) {
            state.discountPlanID = planID
        },
        setDiscountPlanMessage(state, message) {
            state.discountPlanMessage = message
        },
        setEnableMonthlySubscribe(state, enabled) {
            state.enableMonthlySubscribe = enabled
        },
        setThreadingSearchJSON(state, spj) {
            state.threadingSearchJSON = spj
        },
        setTieupSearchJSON(state, spj) {
            state.tieupSearchJSON = spj
        },
        setEditDraftJSON(state, edj) {
            state.editDraftJSON = edj
        },
        setEditDraftSource(state, sourceObj) {
            state.editDraftSource = sourceObj
        }
    }
})
